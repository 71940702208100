import { Customer } from './customer';
import { Staff } from '../people/staff';
import { Rating } from './rating';
import { Message, Category } from '../general';
import { Partner } from '../people';
import { Supplier } from '../finance';

export class Ticket  {
    id: string;
    code: string;
    type: 'BILLING'|'PMT'|'PML'|'ERP';
    complainant: 'STAFF'|'PARTNER'|'CUSTOMER'|'SUPPLIER';
    customer: Customer;
    staff: Staff;
    partner: Partner;
    supplier: Supplier;
    subject: string;
    complaint: string;
    priority: 'LOW'|'NORMAL'|'HIGH';
    category: Category;
    messages: Message[];
    ratings: Rating[];
    status: 'OPEN'|'CLOSED'|'PENDING';
    remark: string;
    officer: string;
    createdBy?: Staff|Partner|Customer|Supplier;
    complainantReply: boolean;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Ticket  {
    id: string;
    code: string;
    type: 'BILLING'|'PMT'|'PML'|'ERP';
    complainant: 'STAFF'|'PARTNER'|'CUSTOMER'|'SUPPLIER';
    customer: Customer;
    staff: Staff;
    partner: Partner;
    supplier: Supplier;
    subject: string;
    complaint: string;
    priority: 'LOW'|'NORMAL'|'HIGH';
    category: Category;
    messages: Message[];
    ratings: Rating[];
    status: 'OPEN'|'CLOSED'|'PENDING';
    remark: string;
    officer: string;
    createdBy?: Staff|Partner|Customer|Supplier;
    complainantReply: boolean;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
