import { Staff } from '../people/staff';
import { Terminal } from '../location/terminal';
import { Category } from '../general/category';
import { Assignment } from './assignment';
import { Partner } from '../people/partner';

export class Gateway {
    id: string;
    code: string;
    flwId: Number;
    txRef: String;
    orderRef: String;
    paymentPlan: String;
    flwCreatedAt: Date;
    amount: Number;
    chargedAmount: Number;
    status: String;
    ipv4: String;
    currency: String;
    customer: Object;
    entity: Object;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    // constructor(fields: any) {
    //     // Quick and dirty extend/assign fields to this model
    //     for (const f in fields) {
    //         // @ts-ignore
    //         this[f] = fields[f];
    //     }
    // }
    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }

}

export interface Gateway {
    id: string;
    code: string;
    flwId: Number;
    txRef: String;
    orderRef: String;
    paymentPlan: String;
    flwCreatedAt: Date;
    amount: Number;
    chargedAmount: Number;
    status: String;
    ipv4: String;
    currency: String;
    customer: Object;
    entity: Object;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

