
import { Staff } from '../people/staff';

export class Team {
    id: string;
    code: string;
    type: string;
    name: string;
    description: string;
    members: Staff[];
    lead: Staff;
    subsidiary: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // tslint:disable-next-line: forin
            for (const f in fields) {
                this[f] = fields[f];
            }
        }
    }

    export interface Team {
        id: string;
        type: string;
        name: string;
        description: string;
        members: Staff[];
        lead: Staff;
        subsidiary: string;
        createdBy?: Staff;
        createdAt?: Date;
        updatedBy?: Staff;
        updatedAt?: Date;
    }
