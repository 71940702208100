import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PmlPartner, ApiResponse, Staff, Terminal } from '../../models';
import { ApiService, EnvService, AuthService } from '../../services';
import { ID, safeGet } from '../../helpers';


@Injectable()
export class PmlPartners {

  pmlPartners: PmlPartner[] = [];
  user: Staff;
  terminalId: Terminal['id'];
  today = new Date().toISOString().slice(0, 10);

  constructor(authService: AuthService, private apiService: ApiService, private env: EnvService) {
    this.user = authService.getUser();
    this.terminalId = safeGet(this.user.terminal, 'id');
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=createdBy`;
    this.recordRetrieve(queryString).then(res => { this.pmlPartners = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.pmlPartners;
    }
    return this.pmlPartners.filter((item) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = item[key];
          if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });
  }

  add(record: PmlPartner) {
    this.pmlPartners.push(record);
  }

  delete(record: PmlPartner) {
    this.pmlPartners.splice(this.pmlPartners.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-partners${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }


  async recordCreate(record: PmlPartner): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-partners`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=createdBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0]);
          });
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: PmlPartner, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-partners/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmlRoutings,pmlPackage,sender,recipient`;
          queryString += `,pmlTerminalFrom,pmlTerminalTo,pmlTerminalStore,deliveryAgent,deliveryStaff,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: PmlPartner): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-partners/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: PmlPartner): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-partners/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async getCashRealized(queryString): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-partners/cash-realized${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res ));
    return await proRes.toPromise();
  }

  async operatePmlShipment(pmlShipment: PmlPartner, operate): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-partners/operation/${pmlShipment.id}`;
    const proRes = this.apiService.updateApi(url, operate).pipe(
      map((res: ApiResponse) => res ));
    return await proRes.toPromise();
  }
}
