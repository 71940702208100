import {PmlTerminal} from './pml-terminal';
import {PmlVehicle} from './pml-vehicle';
import {PmlDriver} from '../people';
import {PmlPartner} from './pml-partner';
import {PmlStaff} from '../people';
import { PmlRoute } from './pml-route';

export class PmlSchedule {
  id: string;
  code: string;
  pmlTerminal: PmlTerminal;
  pmlVehicle: PmlVehicle;
  pmlDriver: PmlDriver;
  pmlPartner: PmlPartner;
  pmlRoutes: PmlRoute[]; // PmlRoute
  vehicleSerial: string;
  boardingDate: Date;
  status: string;
  isReservable: boolean;
  isFull: boolean;
  pmlArrivals: any; PmlArrival
  boardedDate: Date;
  boardedBy: any; // PmlStaff
  loadedBy: any; // PmlStaff
  packagedBy: any; // PmlStaff
  departedBy: any; // PmlStaff
  loadedDate: Date;
  packagedDate: Date;
  departedDate: Date;
  pmlReservations: any; // PmlReservation
  fuelAmount: number;
  allowance: number;
  remark: string;
  createdBy: PmlStaff; // PmlStaff
  updatedBy: PmlStaff; // PmlStaff
  deleted: boolean;
  deletedAt: Date;
  deletedBy: PmlStaff;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}

export interface PmlSchedule {
  id: string;
  code: string;
  pmlTerminal: PmlTerminal;
  pmlVehicle: PmlVehicle;
  pmlDriver: PmlDriver;
  pmlPartner: PmlPartner;
  pmlRoutes: PmlRoute[]; // PmlRoute
  vehicleSerial: string;
  boardingDate: Date;
  status: string;
  isReservable: boolean;
  isFull: boolean;
  pmlArrivals: any; PmlArrival
  boardedDate: Date;
  boardedBy: any; // PmlStaff
  loadedBy: any; // PmlStaff
  packagedBy: any; // PmlStaff
  departedBy: any; // PmlStaff
  loadedDate: Date;
  packagedDate: Date;
  departedDate: Date;
  pmlReservations: any; //PmlReservation
  fuelAmount: number;
  allowance: number;
  remark: string;
  createdBy: PmlStaff; // PmlStaff
  updatedBy: PmlStaff; // PmlStaff
  deleted: boolean;
  deletedAt: Date;
  deletedBy: PmlStaff; //PmlStaff { type: ObjectId, ref: "PmlStaff" },
}
