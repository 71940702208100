import {Staff} from '../people';
import {Merchant, Sale} from '../ecommerce';
import { PmlParcel } from './pml-parcel';
import { Reconciliation } from './reconciliation';

export class MerchantCOD {
  id: string;
  code: string;
  pmlParcel:PmlParcel;
  parcelCode: string;
  merchant: Merchant;
  amount: number;
  sale: Sale;
  status: string;
  reconciliation: Reconciliation;
  createdBy: Staff;
  updatedBy: Staff;
  createdAt: Date;
  updatedAt: Date;
  deleted: boolean;
  deletedAt: Date;
  deletedBy: Staff;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}

export interface MerchantCOD {
  id: string;
  code: string;
  pmlParcel:PmlParcel;
  parcelCode: string;
  merchant: Merchant;
  amount: number;
  sale: Sale;
  status: string;
  reconciliation: Reconciliation;
  createdBy: Staff;
  updatedBy: Staff;
  createdAt: Date;
  updatedAt: Date;
  deleted: boolean;
  deletedAt: Date;
  deletedBy: Staff;
}
