

export class DocumentType {
    id : string;
    name: string;
    issuer: string;
    description: string;
    isRenewable: boolean
    period: string; // enum: PERIOD
    validity: number;
    initialCharge: number;
    renewableCharge: number;
    

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface DocumentType {
    
    id : string;
    name: string;
    issuer: string;
    description: string;
    isRenewable: boolean
    period: string; // enum: PERIOD
    validity: number;
    initialCharge: number;
    renewableCharge: number;
}





