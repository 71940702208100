
import { Staff } from '../people/staff';
import { Customer} from './customer';

export class Workflow {
    id: string;
    code: string;
    customer: Customer;
    salesRep: Staff;
    type: string;
    subsidiary: string;
    tasks: object;
    messages: object;
    items: object;
    project: string;
    budget: number;
    description: string;
    deliverables: string;
    initialContact: Date;
    previousContact: Date;
    nextContact: Date;
    referral: string;
    proposalDueDate: Date;
    assignedDate: Date;
    rating: number;
    result: string;
    status: string;
    remark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // tslint:disable-next-line: forin
            for (const f in fields) {
                this[f] = fields[f];
            }
        }
    }

    export interface Workflow {
        id: string;
        code: string;
        customer: Customer;
        salesRep: Staff;
        type: string;
        subsidiary: string;
        tasks: object;
        messages: object;
        items: object;
        project: string;
        budget: number;
        description: string;
        deliverables: string;
        initialContact: Date;
        previousContact: Date;
        nextContact: Date;
        referral: string;
        proposalDueDate: Date;
        assignedDate: Date;
        rating: number;
        result: string;
        status: string;
        remark: string;
        createdBy?: Staff;
        createdAt?: Date;
        updatedBy?: Staff;
        updatedAt?: Date;
    }
