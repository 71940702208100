import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService, EnvService, AuthService } from '../../services';
import { PmtBoarding, ApiResponse, Staff, PmtTerminal } from '../../models';
import { ID } from '../../helpers';

@Injectable()
export class PmtBoardings {

  pmtBoardings: PmtBoarding[] = [];
  user: Staff;
  terminalId = PmtTerminal['id'];
  today = new Date().toISOString().slice(0, 10);

  constructor(authService: AuthService, private apiService: ApiService, private env: EnvService) {
    this.user = authService.getUser();
    this.terminalId = this.user.terminal.id;
    let queryString = `?sort=-createdAt&createdAt>${this.today}`;
    if(this.terminalId !== ID.EMENE_TERMINAL) queryString += `&terminal=${this.terminalId}`;
    queryString += `&populate=pmtSchedule,pmtSchedule.vehicle,pmtReservation`;
    queryString += `,pmtRoute,customer,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.pmtBoardings = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.pmtBoardings;
    }
    return this.pmtBoardings.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: PmtBoarding) {
    this.pmtBoardings.push(record);
  }

  delete(record: PmtBoarding) {
    this.pmtBoardings.splice(this.pmtBoardings.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pmt/pmt-boardings${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: PmtBoarding): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pmt/pmt-boardings`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmtSchedule,pmtReservation`;
          queryString += `,pmtRoute,customer,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: PmtBoarding, payload): Promise<ApiResponse> {
    const id = !!record.id ? record.id : record._id;
    const url = `${this.env.API_URL}/pmt/pmt-boardings/${id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmtSchedule,pmtReservation`;
          queryString += `,pmtRoute,customer,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }


  async recordPatch(record: PmtBoarding): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pmt/pmt-boardings/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: PmtBoarding): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pmt/pmt-boardings/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

}
