import { Customer } from '../crm/customer';
import { Staff } from '../people/staff';

export class PmlTracking {
  id: string;
  session: string;
  serviceCode: string;
  phone: string;
  text: string;
  description: string;
  createdBy?: Staff|Customer|null;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;
 
  constructor(fields: any) {
    for (const f in fields) {
      this[f] = fields[f];
    }
  }

}

export interface PmlTracking {
  id: string;
  session: string;
  serviceCode: string;
  phone: string;
  text: string;
  description: string;
  createdBy?: Staff|Customer|null;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;
 
}
