import { Staff } from '../people/staff';
import { Customer } from '../crm/customer';
import { Partner } from '../people/partner';

export class Message {
    id: string;
    sender?: 'STAFF'|'CUSTOMER'|'PARTNER';
    recipient?: 'STAFF'|'CUSTOMER'|'PARTNER';
    staff?: Staff;
    customer?: Customer;
    partner?: Partner;
    subject?: string;
    body?: string;
    receiveStatus?: 'UNREAD'|'READ';
    sentStatus?: 'DRAFT'|'SENT';
    trail?: string;
    senderId?: Staff | Customer | Partner;
    senderEmail?: string;
    senderEmailAlternate?: string;
    isEmail?: boolean;
    box? : 'INBOX' | 'OUTBOX';
    createdBy?: Staff | Customer | Partner;
    createdAt?: Date;
    updatedBy?: Staff | Customer | Partner;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Message {
    id: string;
    sender?: 'STAFF'|'CUSTOMER'|'PARTNER';
    recipient?: 'STAFF'|'CUSTOMER'|'PARTNER';
    staff?: Staff;
    customer?: Customer;
    partner?: Partner;
    subject?: string;
    body?: string;
    receiveStatus?: 'UNREAD'|'READ';
    sentStatus?: 'DRAFT'|'SENT';
    trail?: string;
    senderId?: Staff | Customer | Partner;
    senderEmail?: string;
    senderEmailAlternate?: string;
    isEmail?: boolean;
    box? : 'INBOX' | 'OUTBOX';
    createdBy?: Staff | Customer | Partner;
    createdAt?: Date;
    updatedBy?: Staff | Customer | Partner;
    updatedAt?: Date;
}
