import { Terminal } from '../location/terminal';
import { Staff } from '../people/staff';
import { Supplier } from '../finance/supplier';
import { Partner } from '../people/partner';
import { Category } from '../general/category';
import { AccountHeading } from './account-heading';
import { Bank } from './bank';

export class Voucher {
    id: string;
    code: string;
    type: String;
    relatedVoucher?: Voucher;
    stage: number;
    category: Category;
    accountHeading?: AccountHeading;
    terminal?: Terminal;
    subsidiary?: string;
    amount?: number;
    description?: string;
    voucherType: string;
    processing: string;
    beneficiary: 'STAFF'|'PARTNER'|'SUPPLIER';
    beneficiaryBank: Bank;
    beneficiaryAccName: String;
    beneficiaryAccNumber: String;
    staff: Staff;
    pmtPartner: Partner;
    supplier: Supplier;
    rejectedBy: Staff;
    rejectedDate: Date;
    acknowledgedBy: Staff;
    acknowledgedDate: Date;
    endorsedDate: Date;
    endorsedBy: Staff;
    authorizedDate: Date;
    authorizedBy: Staff;
    approvedBy: Staff;
    approvedDate: Date;
    paidBy: Staff;
    paidDate: Date;
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    receivedBy: string;
    receivedDate: Date;
    checkedBy: Staff;
    checkedDate: Date;
    auditedStatus: 'PENDING'|'AUDITED'|'CLOSED'|'REJECTED';
    auditedBy: Staff;
    auditedDate: Date;
    auditedRemark: string;
    remark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Voucher {
    id: string;
    code: string;
    type: String;
    relatedVoucher?: Voucher;
    stage: number;
    category: Category;
    accountHeading?: AccountHeading;
    terminal?: Terminal;
    subsidiary?: string;
    amount?: number;
    description?: string;
    voucherType: string;
    processing: string;
    beneficiary: 'STAFF'|'PARTNER'|'SUPPLIER';
    beneficiaryBank: Bank;
    beneficiaryAccName: String;
    beneficiaryAccNumber: String;
    staff: Staff;
    pmtPartner: Partner;
    supplier: Supplier;
    rejectedBy: Staff;
    rejectedDate: Date;
    acknowledgedBy: Staff;
    acknowledgedDate: Date;
    endorsedDate: Date;
    endorsedBy: Staff;
    authorizedDate: Date;
    authorizedBy: Staff;
    approvedBy: Staff;
    approvedDate: Date;
    paidBy: Staff;
    paidDate: Date;
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    receivedBy: string;
    receivedDate: Date;
    checkedBy: Staff;
    checkedDate: Date;
    auditedStatus: 'PENDING'|'AUDITED'|'CLOSED'|'REJECTED';
    auditedBy: Staff;
    auditedDate: Date;
    auditedRemark: string;
    remark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
