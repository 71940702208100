import { Vehicle } from '../factory/vehicle';
import { Office } from './office';
import { Terminal } from '../location/terminal';
import { Role } from './role';
import { County } from '../location/county';
import { State } from '../location/state';
import { Bank } from '../finance/bank';
import { Category } from '../general';
import { SalaryGrade } from './salary-grade';
import { Entreprise } from './entreprise';

export class Staff {
    _id: string;
    id: string;
    serial?: string;
    category?: Category;
    title?: string;
    surname: string;
    otherName: string;
    fullName: string;
    gender: string;
    birthDate: Date;
    maritalStatus: string;
    children?: number;
    phone?: string;
    phoneHome?: string;
    address?: string;
    village?: string;
    state: State;
    county: County;
    country?: string;
    email?: string;
    password?: string;
    otp?: string;
    otpCount?: number;
    kin: string;
    kinPhone: string;
    kinAddress: string;
    guarantor1: string;
    guarantor1Phone: string;
    guarantor1Address: string;
    guarantor2?: string;
    guarantor2Phone?: string;
    guarantor2Address?: string;
    profession?: string;
    qualification?: string;
    institution?: string;
    tin?: number;
    salaryGrade? : SalaryGrade;
    // annualIncome?: number;
    // basicSalary?: number;
    // bonus?: number;
    // entertainmentAllowance?: number;
    // houseAllowance?: number;
    // lunchAllowance?: number;
    // medicalAllowance?: number;
    // transportAllowance?: number;
    // utilityAllowance?: number;
    // welfareAllowance?: number;
    // pension?: number;
    // assurance?: number;
    bank?: Bank;
    bankAccountNumber?: string;
    bankAccountName?: string;
    rank?: string;
    office: Office;
    role: Role;
    superior: Staff;
    subsidiary?: string;
    terminal: Terminal;
    currentVehicle?: Vehicle;
    remark?: string;
    photo?: string;
    isSalaryPayable?: boolean;
    isDocumentComplete?: boolean;
    accessLevel?: string;
    type?: string;
    enterprise?: Entreprise;

    employment: 'EMPLOYED'|'FULLTIME'|'PARTTIME'|'LEAVE'|'PROBATED'|'SUSPENDED'|'RETIRED'| 'DISENGAGED'|'UNKNOWN';
    employedDate: Date;
    employedBy: Staff;
    parttimedDate: Date;
    parttimedBy: Staff;
    fulltimedDate: Date;
    fulltimedBy: Staff;
    leaveDate: Date;
    leaveBy: Staff;
    probatedDate: Date;
    probatedBy: Staff;
    suspendedDate: Date;
    suspendedBy: Staff;
    retiredDate: Date;
    retiredBy: Staff;
    disengagedDate: Date;
    disengagedBy: Staff;
    employmentRemark: Staff;

    //* Approval
    status: 'PENDING'|'APPROVED'|'REJECTED';
    approvedDate: Date;
    approvedBy: Staff;
    rejectedDate: Date;
    rejectedBy: Staff;
    approvalRemark: String;
    //* Authentication
    lastLogin: Date;
    currentLogin: Date;
    lastIp: String;
    currentIp: String;

    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Staff {
    id: string;
    serial?: string;
    category?: Category;
    title?: string;
    surname: string;
    otherName: string;
    fullName: string;
    gender: string;
    birthDate: Date;
    maritalStatus: string;
    children?: number;
    phone?: string;
    phoneHome?: string;
    address?: string;
    village?: string;
    state: State;
    county: County;
    country?: string;
    email?: string;
    password?: string;
    otp?: string;
    otpCount?: number;
    kin: string;
    kinPhone: string;
    kinAddress: string;
    guarantor1: string;
    guarantor1Phone: string;
    guarantor1Address: string;
    guarantor2?: string;
    guarantor2Phone?: string;
    guarantor2Address?: string;
    profession?: string;
    qualification?: string;
    institution?: string;
    tin?: number;
    salaryGrade? : SalaryGrade;
    // annualIncome?: number;
    // basicSalary?: number;
    // bonus?: number;
    // entertainmentAllowance?: number;
    // houseAllowance?: number;
    // lunchAllowance?: number;
    // medicalAllowance?: number;
    // transportAllowance?: number;
    // utilityAllowance?: number;
    // welfareAllowance?: number;
    // pension?: number;
    // assurance?: number;
    bank?: Bank;
    bankAccountNumber?: string;
    bankAccountName?: string;
    rank?: string;
    office: Office;
    role: Role;
    superior: Staff;
    subsidiary?: string;
    terminal: Terminal;
    currentVehicle?: Vehicle;
    remark?: string;
    photo?: string;
    isSalaryPayable?: boolean;
    isDocumentComplete?: boolean;
    accessLevel?: string;
    type?: string;
    enterprise?: Entreprise;

    employment: 'EMPLOYED'|'FULLTIME'|'PARTTIME'|'LEAVE'|'PROBATED'|'SUSPENDED'|'RETIRED'| 'DISENGAGED'|'UNKNOWN';
    employedDate: Date;
    employedBy: Staff;

    parttimedDate: Date;
    parttimedBy: Staff;
    fulltimedDate: Date;
    fulltimedBy: Staff;
    leaveDate: Date;
    leaveBy: Staff;
    probatedDate: Date;
    probatedBy: Staff;
    suspendedDate: Date;
    suspendedBy: Staff;
    retiredDate: Date;
    retiredBy: Staff;
    disengagedDate: Date;
    disengagedBy: Staff;
    employmentRemark: Staff;

    //* Approval
    status: 'PENDING'|'APPROVED'|'REJECTED';
    approvedDate: Date;
    approvedBy: Staff;
    rejectedDate: Date;
    rejectedBy: Staff;
    approvalRemark: String;
    //* Authentication
    lastLogin: Date;
    currentLogin: Date;
    lastIp: String;
    currentIp: String;

    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

}
