import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BankTransaction, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';

@Injectable()

export class BankTransactions {

    banktransactions: BankTransaction[] = [];

    constructor(private apiService: ApiService, private env: EnvService) {
        let queryString = `?sort=-createdAt`;
        queryString += `&populate=terminal,bankAccount,createdBy,updatedBy`;
        this.recordRetrieve(queryString).then(res => { this.banktransactions = res.payload; console.log(res) });
      }

    query(params?: any) {
        if (!params) {
            return this.banktransactions;
        }
        return this.banktransactions.filter((item) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = item[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return item;
                    } else if (field === params[key]) {
                        return item;
                    }
                }
            }
            return null;
        });
    }

    add(record: BankTransaction) {
        this.banktransactions.push(record);
    }

    delete(record: BankTransaction) {
        this.banktransactions.splice(this.banktransactions.indexOf(record), 1);
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/erp/bank-transactions${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
          map((res: ApiResponse) => res));
        return await proRes.toPromise();
      }
      async recordCreate(record: BankTransaction): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/erp/bank-transactions`;
        const proRes = this.apiService.postApi(url, record).pipe(
          map((res: ApiResponse) => {
            if (res.success && res.payload) {
              console.log('recordCreate() successful =>', res.payload);
              let queryString = `?_id=${res.payload.id}`;
              queryString += `&populate=bank,terminal,createdBy,updatedBy`;
              this.recordRetrieve(queryString).then(res => {
                this.add(res.payload[0])
              })
            }
            return res;
          }));
        return await proRes.toPromise();
      }
    
      async recordUpdate(record: BankTransaction, payload): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/erp/bank-transactions/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
          map((res: ApiResponse) => {
            if (res.success && res.payload) {
              this.delete(record);
              console.log('recordUpdate() successful =>', res.payload);
              let queryString = `?_id=${res.payload.id}`;
              queryString += `&populate=bank,terminal,createdBy,updatedBy`;
              this.recordRetrieve(queryString).then(res => {
                this.add(res.payload[0])
              })
            }
            return res;
          }));
        return await proRes.toPromise();
      }
}
    