import { Merchant } from '../ecommerce';
import {Staff} from '../people';
import { MerchantCOD } from './merchant-cod';

export class Reconciliation {
  id: string;
  code: string;
  merchant: Merchant;
  merchantCods: MerchantCOD[];
  amount: number;
  status: string;
  remark: string;
  createdBy: Staff;
  createdAt: Date;
  updatedBy: Staff;
  updatedAt: Date;
  deleted: boolean;
  deletedAt: Date;
  deletedBy: Staff;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}

export interface Reconciliation {
  id: string;
  code: string;
  merchant: Merchant;
  merchantCods: MerchantCOD[];
  amount: number;
  status: string;
  remark: string;
  createdBy: Staff;
  createdAt: Date;
  updatedBy: Staff;
  updatedAt: Date;
  deleted: boolean;
  deletedAt: Date;
  deletedBy: Staff;
}
