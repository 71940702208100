import { Staff } from '../people/staff';
import { Category } from './category';

export class Setting {
    id: string;
    type: string;
    name: string;
    access: string;
    value: string;
    category: Category;
    description: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // tslint:disable-next-line: forin
            for (const f in fields) {
                this[f] = fields[f];
            }
        }
    }

    export interface Setting {
        id: string;
        type: string;
        name: string;
        access: string;
        value: string;
        category: Category;
        description: string;
        createdBy?: Staff;
        createdAt?: Date;
        updatedBy?: Staff;
        updatedAt?: Date;
    }
