import { Staff } from '../people/staff';
import { Sale } from '../ecommerce/sale';
import { PmlParcel } from '../pml/pml-parcel';
import { PmtBoarding } from '../pmt/pmt-boarding';
import { Terminal } from '../location/terminal';
import { Cart } from '../ecommerce/cart';
import { Rating } from './rating';

export class Customer {
    id: string;
    customerType: string;
    title: string;
    surname: string;
    otherName: string;
    gender: string;
    birthDate: Date;
    phone: string;
    phoneHome: string;
    email: string;
    password: string;
    otp: string;
    otpCount: number;
    contactPerson: string;
    contactPersonPhone: string;
    product: string;
    photo: string;
    address: string;
    country: string;
    website: string;
    isEngrClient: boolean;
    isPmtClient?: boolean;
    isPmlClient: boolean;
    isPetClient: boolean;
    isShopClient: boolean;
    isTenant: boolean;
    company: string;
    industry: string;
    state: string;
    county: string;
    isStaff: boolean;
    wallet: number;
    cart: Cart;
    terminal: Terminal;
    ratings: Rating[];
    sales: Sale[];
    pmtBoardings: PmtBoarding[];
    pmlParcels: PmlParcel[];
    isPhoneVerified: boolean;
    isEmailVerified: boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
    skype?: string;
    linkedin?: string;
    facebook?: string;
    instagram?: string;
    twitter?: string;
    youtube?: string;
    points?: number;
    remark?: string;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Customer {
    id: string;
    type: string;
    customerType: string;
    title: string;
    surname: string;
    otherName: string;
    gender: string;
    birthDate: Date;
    phone: string;
    phoneHome: string;
    email: string;
    password: string;
    otp: string;
    otpCount: number;
    contactPerson: string;
    contactPersonPhone: string;
    product: string;
    photo: string;
    company: string;
    industry: string;
    website: string;
    isEngrClient: boolean;
    address: string;
    state: string;
    county: string;
    isStaff: boolean;
    wallet: number;
    cart: Cart;
    terminal: Terminal;
    ratings: Rating[];
    sales: Sale[];
    pmtBoardings: PmtBoarding[];
    pmlParcels: PmlParcel[];
    country: string;
    isPmtClient?: boolean;
    isPmlClient: boolean;
    isPetClient: boolean;
    isShopClient: boolean;
    isTenant: boolean;
    isPhoneVerified: boolean;
    isEmailVerified: boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
    skype?: string;
    linkedin?: string;
    facebook?: string;
    instagram?: string;
    twitter?: string;
    youtube?: string;
    points?: number;
    remark?: string;
}
