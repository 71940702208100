import { Region, Terminal, Zone } from "../location";
import { Office, Staff } from "../people";

export class Calendar {
    id: string;
    code: string;
    startDate: Date;
    endDate: Date;
    title: string;
    description: string;
    scope: string;
    office: Office;
    terminal: Terminal;
    zone: Zone;
    region: Region;
    subsidiary: string;
    period: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
    
    constructor(fields: any) {
        for (const f in fields) {
            this[f] = fields[f];
            }
        }
}

export interface Calendar {
    id: string;
    code: string;
    startDate: Date;
    endDate: Date;
    title: string;
    description: string;
    scope: string;
    office: Office;
    terminal: Terminal;
    zone: Zone;
    region: Region;
    subsidiary: string;
    period: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}