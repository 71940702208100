import { Staff } from '../people/staff';
import { Category } from './category';

export class Stage {
    id: string;
    code: string;
    category: Category;
    step: number;
    name: string;
    type: string;
    description: string;
    subsidiary: string;
    officer: Staff;
    manhour: number; // Estimate
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Stage {
    id: string;
    code: string;
    category: Category;
    step: number;
    name: string;
    type: string;
    description: string;
    subsidiary: string;
    officer: Staff;
    manhour: number; // Estimate
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
