import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService, EnvService, AuthService } from '../../services';
import { ApiResponse, Staff, PmlTerminal, PmlRoute } from '../../models';
import { ID } from '../../helpers';


@Injectable()
export class PmlRoutes {

  private records: PmlRoute[] = [];
  user: Staff;
  terminalId = PmlTerminal['id'];

  constructor(authService: AuthService, private apiService: ApiService, private env: EnvService) {
    this.user = authService.getUser();
    this.terminalId = this.user.terminal.id;
    let queryString = `?sort=-createdAt`;
    // if(this.terminalId !== ID.EMENE_TERMINAL) queryString += `&pmlTerminalFrom=${this.terminalId}`;
    queryString += `&limit=100&populate=pmlTerminalFrom,pmlTerminalTo,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.records = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.records;
    }
    return this.records.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: PmlRoute) {
    this.records.push(record);
  }

  delete(record: PmlRoute) {
    const index = this.records.findIndex(route => route.id === record.id);
    this.records.splice(index, 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-routes${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: PmlRoute): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-routes`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmlTerminalFrom,pmlTerminalTo,createdBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: PmlRoute, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-routes/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmlTerminalFrom,pmlTerminalTo,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: PmlRoute): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-routes/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: PmlRoute): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-routes/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

}
