import { Staff } from '../people/staff';
import { Role } from './role';

export class Office {
    id: string;
    name: string;
    code: string;
    email: string;
    roles: Array<Role>;
    functions: string;
    hierarchy: number;
    subsidiary: string;
    type: string;
    officeAbove: Office;
    head: Staff;
    assistant: Staff;
    description: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Office {
    // [prop: string]: any;
    id: string;
    name: string;
    code: string;
    email: string;
    roles: Array<Role>;
    functions: string;
    hierarchy: number;
    subsidiary: string;
    type: string;
    officeAbove: Office;
    head: Staff;
    assistant: Staff;
    description: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
