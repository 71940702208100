
import { Staff } from '../people/staff';
import { Blog } from './blog';

export class BlogComment {
    id: string;
    blogpost: Blog;
    relatedComment: BlogComment;
    comment: string;
    isPublished: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface BlogComment {
    id: string;
    blogpost: Blog;
    relatedComment: BlogComment;
    comment: string;
    isPublished: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

}
