import { Staff } from '../people/staff';
import { Terminal } from '../location/terminal';

export class Attendance {
    id: string;
    code: string;
    staff: Staff;
    paidBy: Staff;
    paidDate: Date;
    payStatus: string;
    subsidiary: string;
    terminal	: Terminal;
    arrivalTime: Date;
    departureTime: Date;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Attendance {
    id: string;
    code: string;
    staff: Staff;
    paidBy: Staff;
    paidDate: Date;
    payStatus: string;
    subsidiary: string;
    terminal	: Terminal;
    arrivalTime: Date;
    departureTime: Date;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
