import { Staff } from '../people/staff';
import { Account } from './account';
import { Transaction } from './transaction';


export class Royalty {
    id: string;
    user: Account;
    transaction: Transaction;
    points: Number;
    isClaimed: Boolean;
    description: String;
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Royalty {
    id: string;
    user: Account;
    transaction: Transaction;
    points: Number;
    isClaimed: Boolean;
    description: String;
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;
}
