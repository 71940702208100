import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, AccountClass } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class AccountClasses {

  accountClasses: AccountClass[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
   let queryString = `?populate=category,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.accountClasses = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.accountClasses;
    }
    return this.accountClasses.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: AccountClass) {
    this.accountClasses.push(record);
  }

  delete(record: AccountClass) {
    this.accountClasses.splice(this.accountClasses.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/account-classes${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: AccountClass): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/account-classes`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=category,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: AccountClass, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/account-classes/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=category,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: AccountClass): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/account-classes/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: AccountClass): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/account-classes/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
