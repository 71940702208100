import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ParkBankRegister, ApiResponse, Terminal, Staff } from '../../models';
import { ApiService, EnvService, AuthService } from '../../services';
import { ID } from '../../helpers';


@Injectable()
export class ParkBankRegisters {

  parkBankRegisters: ParkBankRegister[] = [];
  user: Staff;
  terminalId = Terminal['id'];

  constructor(authService: AuthService, private apiService: ApiService, private env: EnvService) {
    this.user = authService.getUser();
    this.terminalId = this.user.terminal.id;
    let queryString = `?limit=2000`;
    if(this.terminalId !== ID.EMENE_TERMINAL) {
      queryString += `&terminal=${this.terminalId}`;
    }
    queryString += `&populate=terminal,auditedBy,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.parkBankRegisters = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.parkBankRegisters;
    }
    return this.parkBankRegisters.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

    add(record: ParkBankRegister) {
      this.parkBankRegisters.push(record);
    }

    delete(record: ParkBankRegister) {
      this.parkBankRegisters.splice(this.parkBankRegisters.indexOf(record), 1);
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/park/bank-registers${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
          map((res: ApiResponse) => res));
        return await proRes.toPromise();
      }


      async recordCreate(record: ParkBankRegister): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/park/bank-registers`;
        const proRes = this.apiService.postApi(url, record).pipe(
          map((res: ApiResponse) => {
            if (res.success && res.payload) {
              console.log('recordCreate() successful =>', res.payload);
              let queryString = `?_id=${res.payload.id}`;
              queryString += `&populate=terminal,auditedBy,createdBy,updatedBy`;
              this.recordRetrieve(queryString).then(res => {
                this.add(res.payload[0])
              })
            }
            return res;
          }));
        return await proRes.toPromise();
      }
    
      async recordUpdate(record: ParkBankRegister, payload): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/park/bank-registers/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
          map((res: ApiResponse) => {
            if (res.success && res.payload) {
              this.delete(record);
              console.log('recordUpdate() successful =>', res.payload);
              let queryString = `?_id=${res.payload.id}`;
              queryString += `&populate=terminal,auditedBy,createdBy,updatedBy`;
              this.recordRetrieve(queryString).then(res => {
                this.add(res.payload[0])
              })
            }
            return res;
          }));
        return await proRes.toPromise();
      }
    
      async recordPatch(record: ParkBankRegister): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/park/bank-registers/${record.id}`;
        const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
          map((res: ApiResponse) => {
            this.delete(record);
            return res;
          }));
        return await proRes.toPromise();
      }
    
      async recordDelete(record: ParkBankRegister): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/park/bank-registers/${record.id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
          map((res: ApiResponse) => {
            this.delete(record);
            return res;
          }));
        return await proRes.toPromise();
      }
    }
    