import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-appanalyst-modal',
  templateUrl: './appanalyst-modal.component.html',
  styleUrls: ['./appanalyst-modal.component.scss']
})
export class AppanalystModalComponent implements OnInit, OnChanges {

  @Input() heading: string;
  // tslint:disable-next-line:no-input-rename
  @Input('openModal') outerOpenModal: string;
  prevOpenModal: string;
  openModal: boolean;

  constructor() { }

  ngOnChanges() {
    if (this.prevOpenModal !== this.outerOpenModal) {
      if (/^closeModal/.test(this.outerOpenModal)) {
        this.openModal = false;
      } else {
        this.openModal = true;
      }
      this.prevOpenModal = this.outerOpenModal;
    }

  }

  ngOnInit() {
  }

  closeModal() {
    this.openModal = false;
  }

}
