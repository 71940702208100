import { Staff } from '../people/staff';
import { PmlParcel } from './pml-parcel';
import { PmlRouting } from './pml-routing';
import { Partner } from '../people/partner';
import { PmlTerminal } from './pml-terminal';

export class PmlPackage {
    id: string;
    code: string;
    name: string;
    // recentCustodian: "PARTNER"|"STAFF";
    // recentPartner: Partner;
    // recentStaff: Staff;
    pmlParcels: PmlParcel[];
    pmlRoutings: PmlRouting[];
   // transportCharge: Number;
    status: DeliveryStatus;
    pmlTerminalFrom: PmlTerminal;
    pmlTerminalStore: PmlTerminal;
    pmlTerminalTo: PmlTerminal;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }

}

export interface PmlPackage {
    id: string;
    code: string;
    name: string;
    // recentCustodian: "PARTNER"|"STAFF";
    // recentPartner: Partner;
    // recentStaff: Staff;
    pmlParcels: PmlParcel[];
    pmlRoutings: PmlRouting[];
   // transportCharge: Number;
    status: DeliveryStatus;
    pmlTerminalFrom: PmlTerminal;
    pmlTerminalStore: PmlTerminal;
    pmlTerminalTo: PmlTerminal;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

enum DeliveryStatus {
    PENDING = 'PENDING',
    STORED = 'STORED',
    TRANSIT = 'TRANSIT',
    DELIVERED = 'DELIVERED',
    DISCHARGED = 'DISCHARGED',
}
