import { Staff } from '../people/staff';
import { Voucher } from '../finance/voucher';
import { Office } from '../people/office';
import { Category } from './category';
import { Assignment } from '../finance/assignment';

export class Task {
    id: string;
    tags: string;
    code: string;
    category: Category;
    status: 'PENDING'|'ASSIGNED'|'ONGOING'|'STARTED'|'ENDING'|'CLOSED';
    name: string;
    title: string;
    description: string;
    manhour: string;
    completion: Number;
    feedbacks: [];
    startDate: Date;
    endDate: Date;
    assignedDate: Date;
    assignedTo: Staff;
    assignedBy: Staff;
    score: Number;
    remark: string;
    voucher: Voucher;
    assignments: Assignment[];
    subsidiary: string;
    office: Office;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Task {
    // [prop: string]: any;
    id: string;
    tags: string;
    code: string;
    category: Category;
    status: 'PENDING'|'ASSIGNED'|'ONGOING'|'STARTED'|'ENDING'|'CLOSED';
    name: string;
    title: string;
    description: string;
    manhour: string;
    completion: Number;
    feedbacks: [];
    startDate: Date;
    endDate: Date;
    assignedDate: Date;
    assignedTo: Staff;
    assignedBy: Staff;
    score: Number;
    remark: string;
    voucher: Voucher;
    assignments: Assignment[];
    subsidiary: string;
    office: Office;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date; 
}
