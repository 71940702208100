import { Staff } from '../people/staff';
import { Category } from '../general/category';

export class AccountClass {
    id: string;
    code: string;
    name: string;
    division: string;
    description: string;
    category: Category;
    classType: string;
    subsidiary: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }
}

export interface AccountClass {
    id: string;
    code: string;
    name: string;
    division: string;
    description: string;
    category: Category;
    classType: string;
    subsidiary: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
