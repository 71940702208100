import { Entrepreneurs } from "src/app/providers";
import { Bank } from "../finance";
import { PmtRoute, PmtVehicle } from "../pmt";
import { Staff } from "./staff";

export class Entreprise {
    id: string;
    _id: string;
    name: string;
    code : string;
    remark: string;
    pmtRoutes: Array<PmtRoute>;
    entrepreneurs: Array<Entrepreneurs>;
    vehicles: Array<PmtVehicle>;
    country : string;
    bank : Bank;
    bankAccountName : string;
    bankAccountNumber : number;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;


    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Entreprise {
    id: string;
    _id: string;
    name: string;
    code : string;
    remark: string;
    pmtRoutes: Array<PmtRoute>;
    entrepreneurs: Array<Entrepreneurs>;
    vehicles: Array<PmtVehicle>;
    country : string;
    bank : Bank;
    bankAccountName : string;
    bankAccountNumber : number;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
