import { County } from '../location/county';
import { Staff } from '../people/staff';
import { PmlRegion } from './pml-region';

export class PmlZone {
    id: string;
    code: string;
    region: PmlRegion;
    name: string;
    description: string;
    subsidiary: string;
    counties: County[];
    manager: Staff;
    supervisor: Staff;
    isActive: boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface PmlZone {
    id: string;
    code: string;
    region: PmlRegion;
    name: string;
    description: string;
    subsidiary: string;
    counties: County[];
    manager: Staff;
    supervisor: Staff;
    isActive: boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
