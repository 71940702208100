import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Merchant, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Merchants {

  merchants: Merchant[] = [];
  private queryString: string;

  constructor(private apiService: ApiService, private env: EnvService) {
    this.queryString = `?sort=-createdAt,-updatedAt&limit=1000`;
    this.queryString += `&populate=cart,terminal,ratings,saleOrders,pmtBoardings,pmlParcels,createdBy,updatedBy`;
    this.recordRetrieve(this.queryString).then(res => { this.merchants = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.merchants;
    }
    return this.merchants.filter((merchant) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = merchant[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return merchant;
            } else if (field === params[key]) {
              return merchant;
            }
          }
      }
      return null;
    });
  }

  add(record: Merchant) {
    this.merchants.push(record);
  }

  delete(record: Merchant) {
    this.merchants.splice(this.merchants.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ecommerce/merchants${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Merchant): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ecommerce/merchants`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          this.queryString += `&populate=cart,terminal,ratings,saleOrders,pmtBoardings,pmlShipments,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
}

async recordUpdate(record: Merchant, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ecommerce/merchants/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          this.queryString += `&populate=cart,terminal,ratings,saleOrders,pmtBoardings,pmlShipments,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
}

async recordPatch(record: Merchant): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ecommerce/merchants/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Merchant): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ecommerce/merchants/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }


  async getMerchant(prop: string, val: any): Promise<Merchant> {
    console.log('\nCalling getMerchant');
    const merchantObj = this.merchants.find(item => item[ prop ] === val);
    if (merchantObj) return merchantObj;
    const query = prop === 'id' ? `?_id=${val}` : `?${prop}=${val}`;
    await this.recordRetrieve(query)
      .then((res: ApiResponse) => {
        console.log('\ngetMerchant ==>', res);
        if (res.success) return res.payload[0] || [];
      }).catch(e => e.message);
  }
}
