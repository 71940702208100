import { AccountHeading } from './account-heading';
import { Staff } from '../people/staff';

export class AccountPosting {
    id: string;
    code: string;
    amount: number;
    description: string;
    transactionDate: Date;
    transactionDetail: object;
    postingType: string; // "DEBIT|CREDIT"
    category: string; // "INCOME|EXPENSES"
    accountHeading: AccountHeading;
    createdBy?: Staff;
    createdAt?: Date;
    uptadedBy: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }

}

export interface AccountPosting {
    id: string;
    code: string;
    amount: number;
    description: string;
    transactionDate: Date;
    transactionDetail: object;
    postingType: string; // "DEBIT|CREDIT"
    category: string; // "INCOME|EXPENSES"
    accountHeading: AccountHeading;
    createdBy?: Staff;
    createdAt?: Date;
    uptadedBy: Staff;
    updatedAt?: Date;
}
