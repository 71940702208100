import { Staff } from '../people/staff';

export class EcommerceSetting {
    id: string;
    name: string;
    access: string;
    value: string;
    category: string;
    description: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }
}

export interface EcommerceSetting {
    id: string;
    name: string;
    access: string;
    value: string;
    category: string;
    description: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
