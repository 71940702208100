import { Vehicle } from "../factory";
import { Staff } from "../people";

export class Documentation {
    id : string;
    documentType: DocumentType;
    vehicle: Vehicle;
    vehicleSerial: string;
    photo: string; // url
    reference: string;
    lastRenewal: Date;
    nextRenewal: Date;
    renewalBy: Staff;
    amount: number;  // comment: "fee or renewal charge"
    description: string;
    isRenewed: boolean;
    isValidity: boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            this[f] = fields[f];
        }
    }
}

export interface Documentation {
    id : string;
    documentType: DocumentType;
    vehicle: Vehicle;
    vehicleSerial: string;
    photo: string; // url
    reference: string;
    lastRenewal: Date;
    nextRenewal: Date;
    renewalBy: Staff;
    amount: number;  // comment: "fee or renewal charge"
    description: string;
    isRenewed: boolean;
    isValidity: boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}




