import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, PayrollDetail } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class PayrollDetails {

  payrollDetails: PayrollDetail[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=payroll,staff,paidBy,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.payrollDetails = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.payrollDetails;
    }
    return this.payrollDetails.filter((payrollDetail) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = payrollDetail[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return payrollDetail;
            } else if (field === params[key]) {
              return payrollDetail;
            }
          }
      }
      return null;
    });
  }

  add(record: PayrollDetail) {
    this.payrollDetails.push(record);
  }

  delete(record: PayrollDetail) {
    this.payrollDetails.splice(this.payrollDetails.indexOf(record), 1);
  }

 
  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/payroll-details${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }


  async recordCreate(record: PayrollDetail): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/payroll-details`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=payroll,staff,paidBy,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: PayrollDetail, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/payroll-details/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=payroll,staff,paidBy,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: PayrollDetail): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/payroll-details/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: PayrollDetail): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/payroll-details/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
