import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiResponse, Voucher, Terminal, Staff } from '../../models';
import { ApiService, EnvService, AuthService } from '../../services';
import { ID, getLocalStorage } from '../../helpers';


@Injectable()
export class Vouchers {

  vouchers: Voucher[] = [];
  user: Staff = getLocalStorage('user');
  terminalId = Terminal['id'];

  constructor(authService: AuthService, private apiService: ApiService, private env: EnvService) {
    this.user = authService.getUser();
    this.terminalId = this.user.terminal.id;
    let queryString = `?staff=${this.user.id}&sort=-createdAt&limit=100&populate=staff,accountHeading`;
    // if(this.terminalId !== ID.EMENE_TERMINAL) {
    //   queryString += `&terminal=${this.terminalId}`;
    // }
  //  queryString += `&populate=approvedBy,paidBy,receivedBy,checkedBy,auditedBy`;
   // queryString +=',relatedVoucher,category,accountHeading,terminal,staff,pmtPartner'
   // queryString +=',acknowledgedBy,endorsedBy,authorizedBy,createdBy,updatedBy,beneficiaryBank'
    this.recordRetrieve(queryString).then(res => { this.vouchers = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.vouchers;
    }
    return this.vouchers.filter((voucher) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = voucher[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return voucher;
            } else if (field === params[key]) {
              return voucher;
            }
          }
      }
      return null;
    });
  }

  add(record: Voucher) {
    this.vouchers.push(record);
  }

  delete(record: Voucher) {
    this.vouchers.splice(this.vouchers.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/vouchers${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }


  async recordCreate(record: Voucher): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/vouchers`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
        //  queryString += `&populate=approvedBy,paidBy,receivedBy,checkedBy,auditedBy`;
         // queryString +=',relatedVoucher,category,accountHeading,terminal,staff,pmtPartner'
        //  queryString +=',acknowledgedBy,endorsedBy,authorizedBy,createdBy,updatedBy'
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: Voucher, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/vouchers/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
        //  queryString += `&populate=approvedBy,paidBy,receivedBy,checkedBy,auditedBy`;
        //  queryString +=',relatedVoucher,category,accountHeading,terminal,staff,pmtPartner'
        //  queryString +=',acknowledgedBy,endorsedBy,authorizedBy,createdBy,updatedBy'
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }
  async recordOperation(record: Voucher, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/vouchers/operation/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          let queryString = `?_id=${res.payload.id}`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: Voucher): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/vouchers/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Voucher): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/vouchers/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
