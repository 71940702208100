import { Staff } from '../people/staff';


export class Charge {
    id: string;
    code: string; 
    name: string ;
    value: string ;
    type: "BUYING" | "SPENDING" | "WITHDRAWAL" | "TRANSFER";
    description: string;
    isWaivable: boolean;
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Charge {
    id: string;
    code: string; 
    name: string ;
    value: string ;
    type: "BUYING" | "SPENDING" | "WITHDRAWAL" | "TRANSFER";
    description: string;
    isWaivable: boolean;
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;
}
