import { Entreprise, Staff } from '.';
import { Terminal } from '..';

export class Disengagement {
    _id: string;
    id: string;
    code: string;
    staff: Staff;
    status: 'PENDING'|'APPROVED'|'REJECTED';
    type: 'RESIGNATION'| 'RETIREMENT' |'SUSPENSION';
    reason: string;
    userType: string;
    terminal: Terminal;
    subsidiary: string;
    enterprise: Entreprise;
    appliedDate: Date;
    approvedBy: Staff;
    approvedDate: Date;
    remark: string;

    constructor(fields: any) {
        // tslint:disable-next-line: forin
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Disengagement {
    _id: string;
    id: string;
    code: string;
    staff: Staff;
    status: 'PENDING'|'APPROVED'|'REJECTED';
    type: 'RESIGNATION'| 'RETIREMENT' |'SUSPENSION';
    reason: string;
    userType: string;
    terminal: Terminal;
    subsidiary: string;
    enterprise: Entreprise;
    appliedDate: Date;
    approvedBy: Staff;
    approvedDate: Date;
    remark: string;
}