import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Sale, ApiResponse, Terminal, EcommerceStore, Customer, Staff, Product } from '../../models';
import { ApiService, EnvService } from '../../services';
import { deepPropsExist } from '../../helpers';
import { Terminals } from '../location/terminals';
import { EcommerceStores } from './ecommerce-stores';
import { Customers } from '../crm/customers';
import { Staffs } from '../people/staffs';
import { Products } from './products';


@Injectable()
export class Sales {

  sales: Sale[] = [];
  terminalRecords: Terminal[] = [];
  storeRecords: EcommerceStore[] = [];
  customerRecords: Customer[] = [];
  staffRecords: Staff[] = [];
  productRecords: Product[] = [];

  constructor(
      private apiService: ApiService,
        private env: EnvService,
        private terminals: Terminals,
        private stores: EcommerceStores,
        private customers: Customers,
        private staffs: Staffs,
        private products: Products,
    ) {
    let queryString = `?sort=name`;
    queryString += `&populate=items.product,terminal,store,customer,staff`;
    this.recordRetrieve(queryString).then(res => { this.sales = res.payload; console.log(res) });

    this.recordRetrieve();
    this.terminalRecords = this.terminals.query();
    this.storeRecords = this.stores.query();
    this.customerRecords = this.customers.query();
    this.staffRecords = this.staffs.query();
    this.productRecords = this.products.query();
  }

  query(params?: any) {
    if (!params) {
      return this.sales;
    }
    return this.sales.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: Sale) {
    this.sales.push(record);
  }

  delete(record: Sale) {
    const index = this.sales.findIndex(Sale => Sale.id === record.id);
    this.sales.splice(index, 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ecommerce/sales${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async verifyTransaction(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ecommerce/sales/verify${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Sale): Promise<ApiResponse> {
      const url = `${this.env.API_URL}/ecommerce/sales`;
      const proRes = this.apiService.postApi(url, record).pipe(
          map((res: ApiResponse) => {
              if (res.success && res.payload) {
                this.add(this.modifiedPayload(res.payload));
              } else {
                  throwError(res.message);
              }
              return res;
          }));
      return await proRes.toPromise();
  }

  async recordUpdate(record: Sale, payload): Promise<ApiResponse> {
      const url = `${this.env.API_URL}/ecommerce/sales/${record.id}`;
      const proRes = this.apiService.updateApi(url, payload).pipe(
          map((res: ApiResponse) => {
              if (res.success) {
                this.delete(record);
                console.log(res.payload);
                this.add(this.modifiedPayload(res.payload));
              } else {
                  throwError(res.message);
              }
              return res;
          }));
      return await proRes.toPromise();
  }

  async recordPatch(record: Sale): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ecommerce/sales/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Sale): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ecommerce/sales/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  private modifiedPayload(payload: any) {
    const terminal = deepPropsExist(payload, 'terminal') && payload.ternimal !== null
    ? this.terminalRecords.find(terminal => terminal.id === payload.terminal) : null;
    const staff = deepPropsExist(payload, 'staff') && payload.staff !== null
    ? this.staffRecords.find(staff => staff.id === payload.staff) : null;
    const customer = deepPropsExist(payload, 'customer') && payload.customer !== null
    ? this.customerRecords.find(supplier => supplier.id === payload.customer) : null;
    const store = deepPropsExist(payload, 'store') && payload.store !== null
    ? this.storeRecords.find(store => store.id === payload.store) : null;
    const items = payload.items.map(item => {
        const product = this.productRecords.find(prod => prod.id === item.product);
        const newItem = {...item, ...{product}};
        return newItem;
    });

    return {...payload, ...{customer, terminal, staff, store, items}};
  }

}
