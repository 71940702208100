import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { EcommerceStore, ApiResponse, Terminal, Customer, Staff } from '../../models';
import { ApiService, EnvService } from '../../services';
import { deepPropsExist } from '../../helpers';
import { Terminals } from '../location/terminals';
import { Customers } from '../crm/customers';
import { Staffs } from '../people/staffs';


@Injectable()
export class EcommerceStores {

  stores: EcommerceStore[] = [];
  terminalRecords: Terminal[] = [];
  customerRecords: Customer[] = [];
  staffRecords: Staff[] = [];

  constructor(
      private apiService: ApiService,
        private env: EnvService,
        private terminals: Terminals,
        private customers: Customers,
        private staffs: Staffs,
    ) {
    let queryString = `?sort=name`;
    queryString += `&populate=items.product,terminal,keeper,store,customer,staff`;
    this.recordRetrieve(queryString).then(res => { this.stores = res.payload; console.log(res) });

    this.recordRetrieve();
    this.terminalRecords = this.terminals.query();
    this.customerRecords = this.customers.query();
    this.staffRecords = this.staffs.query();
  }

  query(params?: any) {
    if (!params) {
      return this.stores;
    }
    return this.stores.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: EcommerceStore) {
    this.stores.push(record);
  }

  delete(record: EcommerceStore) {
    const index = this.stores.findIndex(Store => Store.id === record.id);
    this.stores.splice(index, 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ecommerce/store${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: EcommerceStore): Promise<ApiResponse> {
      const url = `${this.env.API_URL}/ecommerce/store`;
      const proRes = this.apiService.postApi(url, record).pipe(
          map((res: ApiResponse) => {
              if (res.success && res.payload) {
                this.add(this.modifiedPayload(res.payload));
              } else {
                  throwError(res.message);
              }
              return res;
          }));
      return await proRes.toPromise();
  }

  async recordUpdate(record: EcommerceStore, payload): Promise<ApiResponse> {
      const url = `${this.env.API_URL}/ecommerce/store/${record.id}`;
      const proRes = this.apiService.updateApi(url, payload).pipe(
          map((res: ApiResponse) => {
              if (res.success) {
                this.delete(record);
                console.log(res.payload);
                this.add(this.modifiedPayload(res.payload));
              } else {
                  throwError(res.message);
              }
              return res;
          }));
      return await proRes.toPromise();
  }

  async recordPatch(record: EcommerceStore): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ecommerce/store/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: EcommerceStore): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ecommerce/store/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  private modifiedPayload(payload: any) {
    const terminal = deepPropsExist(payload, 'terminal') && payload.ternimal !== null
    ? this.terminalRecords.find(terminal => terminal.id === payload.terminal) : null;
    const staff = deepPropsExist(payload, 'staff') && payload.staff !== null
    ? this.staffRecords.find(staff => staff.id === payload.staff) : null;
    const customer = deepPropsExist(payload, 'customer') && payload.customer !== null
    ? this.customerRecords.find(supplier => supplier.id === payload.customer) : null;
    // const items = payload.items.map(item => {
    //     const product = this.productRecords.find(prod => prod.id === item.product);
    //     const newItem = {...item, ...{product}};
    //     return newItem;
    // });

    return {...payload, ...{customer, terminal, staff}};
  }

}
