import { Staff } from "./staff";

export class SalaryGrade {
    id: string;
    code: string;
    level: number;
    step: number;
    netSalary: number;
    grossSalary: number;
    // amount: number;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface SalaryGrade {
    id: string;
    code: string;
    level: number;
    step: number;
    netSalary: number;
    grossSalary: number;
   // amount: number;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
