import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Customer} from '../../models/crm';
import {Customers} from '../../providers/crm';
import {ToastrService} from 'ngx-toastr';
import {isEmpty} from 'lodash';

@Component({
  selector: 'app-global-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit {
  @Input() currentForm: string;
  @Input() customer: Customer | null;
  @Output() updatedCustomer = new EventEmitter<Customer>();
  addForm: FormGroup;
  updateForm: FormGroup;
  loading: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private customers: Customers,
    private toastr: ToastrService) {
    this.createForm();
    this.editForm();
  }

  ngOnInit() {
    if (!isEmpty(this.customer)) {
      console.log(this.customer);
      this.setUpdateForm();
    }
  }

  ngOnChanges() {
    if (this.customer !== null) {
      this.setUpdateForm();
    }
  }

  createForm() {
    this.addForm = this.formBuilder.group({
      surname: [null, Validators.required],
      otherName: [null, Validators.required],
      gender: [null, Validators.required],
      phone: [null, Validators.required],
      contactPerson: [null, Validators.required],
      contactPersonPhone: [null, Validators.required],
    });
  }

  editForm() {
    this.updateForm = this.formBuilder.group({
      surname: [null, Validators.required],
      otherName: [null, Validators.required],
      gender: [null, Validators.required],
      phone: [null, Validators.required],
      contactPerson: [null, Validators.required],
      contactPersonPhone: [null, Validators.required],
    });
  }

  setUpdateForm() {
    this.updateForm.patchValue({
      surname: !isEmpty(this.customer.surname) ? this.customer.surname : null,
      otherName: !isEmpty(this.customer.otherName) ? this.customer.otherName : null,
      gender: !isEmpty(this.customer.gender) ? this.customer.gender : null,
      phone: !isEmpty(this.customer.phone) ? this.customer.phone : null,
      contactPerson: !isEmpty(this.customer.contactPerson) ? this.customer.contactPerson : null,
      contactPersonPhone: !isEmpty(this.customer.contactPersonPhone) ? this.customer.contactPersonPhone : null,
    });
  }

  onSubmit() {
    this.loading = true;
    const payload = this.addForm.value;
    if (this.addForm.invalid) {
      this.showNotification('Invalid form! Please fill all the required* inputs.');
      this.loading = false;
      return;
    }
    payload.isPmtClient = true;
    payload.password = payload.phone;
    payload.country = 'NG';
    payload.type = 'REGULAR';
    console.log(payload);
    this.customers.recordCreate(payload).then(res => {
      const customer = res.payload;
      this.updatedCustomer.emit(customer);
      this.showNotification('Successfully, created customer record');
      this.addForm.reset();
    }).catch(error => this.showNotification(error));
    this.loading = false;
    return;
  }

  onUpdate() {
    this.loading = true;
    const payload = this.updateForm.value;
    if (this.customer !== null) {
      return false;
    }
    console.log(payload);
    this.customers.recordUpdate(this.customer, payload).then(res => {
      const customer = res.payload;
      this.updatedCustomer.emit(customer);
      this.showNotification('Successfully, updated customer record');
      this.updateForm.reset();
    }).catch(error => this.showNotification(error)).finally(() => {
      this.loading = false;
      return;
    });
  }

  showNotification(message) {
    this.toastr.show(message, 'Adding Record', {
      timeOut: 8000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-primary alert-with-icon',
    });
  }

}
