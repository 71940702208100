import { Staff } from '../people/staff';
import { Voucher } from './voucher';
import { Payroll } from './payroll';

export class PayrollDetail {
    id: string;
    payroll: Payroll;
    code: string;	
    staff: Staff;	
    salary: Number;
    isPaid: Boolean;	
    paidDate: Date; 	
    paidBy: Staff;	
    remark 	: string;
    paymentMethod: string; 	
    paymentGateway: string;	
    paymentStatus: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface PayrollDetail {
    // [prop: string]: any;
    id: string;
    payroll: Payroll;
    code: string;	
    staff: Staff;	
    salary: Number;
    isPaid: Boolean;	
    paidDate: Date; 	
    paidBy: Staff;	
    remark 	: string;
    paymentMethod: string; 	
    paymentGateway: string;	
    paymentStatus: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

