import { City } from './city';
import { County } from './county';
import { Staff } from '../people/staff';
import { State } from './state';
import { Category } from '../general';
import { PmlZone } from '../pml';
import { PmlRegion } from '../pml';
import { Rating } from '../crm';

export class Terminal {

    id: string;
    code: string;
    type: string;
    category: Category;
    name: string;
    manager?: Staff;
    phone?: string;
    quarter?: string;
    city?: City;
    county?: County;
    address?: string;
    longitude?: number;
    latitude?: number;
    capacity?: number;
    photo?: string;
    subsidiary: string;
    supervisor: Staff;
    email: string;
    zone: PmlZone;
    region: PmlRegion;
    isActive: boolean;
    ratings: Rating[];
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;


    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }

}

export interface Terminal {
    [prop: string]: any;
    id: string;
    code: string;
    type: string;
    category: Category;
    name: string;
    manager?: Staff;
    phone?: string;
    quarter?: string;
    city?: City;
    county?: County;
    address?: string;
    longitude?: number;
    latitude?: number;
    capacity?: number;
    photo?: string;
    subsidiary: string;
    supervisor: Staff;
    email: string;
    zone: PmlZone;
    region: PmlRegion;
    isActive: boolean;
    ratings: Rating[];
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

