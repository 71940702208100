import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Spare, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Spares {

    spares: Spare[] = [];

    constructor(private apiService: ApiService, private env: EnvService) {
        const records = []; // Initial Values
        for (const item of records) {
            this.spares.push(new Spare(item));
        }
        let queryString = `?sort=-createdAt`;
        queryString += `&populate=officer,category,createdBy,updatedBy`;
        this.recordRetrieve(queryString).then(res => { this.spares = res.payload; console.log(res) });
    }

    query(params?: any) {
        if (!params) {
            return this.spares;
        }
        return this.spares.filter((item) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = item[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return item;
                    } else if (field === params[key]) {
                        return item;
                    }
                }
            }
            return null;
        });
    }

    add(record: Spare) {
        this.spares.push(record);
    }

    delete(record: Spare) {
        this.spares.splice(this.spares.indexOf(record), 1);
    }


    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/erp/spares${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => res));
        return await proRes.toPromise();
    }

    async recordCreate(record: Spare): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/erp/spares`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    console.log('recordCreate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=officer,category,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordUpdate(record: Spare, payload): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/erp/spares/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    console.log('recordUpdate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=officer,category,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordPatch(record: Spare): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/erp/spares/${record.id}`;
        const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordDelete(record: Spare): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/erp/spares/${record.id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }
}
