import { Staff } from '../people/staff';

export class PmlBillingTier {
  id: string;
  type: "FORWARD" | "REVERSE";
  density: number;
  billingTier1: number;
  billingTier2: number;
  billingTier3: number;
  billingTier4: number;
  billingTier5: number;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }

}

export interface PmlBillingTier {
// [prop: string]: any;
  id: string;
  type: "FORWARD" | "REVERSE";
  density: number;
  billingTier1: number;
  billingTier2: number;
  billingTier3: number;
  billingTier4: number;
  billingTier5: number;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;
}

