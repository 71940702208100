import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntrepriseAssignment, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class EntrepriseAssignments {

  entrepriseAssignments: EntrepriseAssignment[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt&limit=100`;
    queryString += `&populate=category,staff,pmtPartner,partner,vehicle,asset,issuedBy,approvedBy,returnedBy`;
    queryString += `,rejectedBy,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.entrepriseAssignments = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.entrepriseAssignments;
    }
    return this.entrepriseAssignments.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: EntrepriseAssignment) {
    // this.entrepriseAssignments.push(record);
    this.entrepriseAssignments.unshift(record);
  }

  delete(record: EntrepriseAssignment) {
    this.entrepriseAssignments.splice(this.entrepriseAssignments.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/enterprise-assignments${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: EntrepriseAssignment): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/enterprise-assignments`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          const id = res.payload.id || res.payload._id;
          let queryString = `?_id=${id}`;
          queryString += `&populate=category,staff,pmtPartner,partner,vehicle,asset,issuedBy,approvedBy,returnedBy`;
          queryString += `,rejectedBy,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: EntrepriseAssignment, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/enterprise-assignments/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=category,staff,pmtPartner,partner,vehicle,asset,issuedBy,approvedBy,returnedBy`;
          queryString += `,rejectedBy,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: EntrepriseAssignment): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/enterprise-assignments/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: EntrepriseAssignment): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/enterprise-assignments/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async updateOperation(record: EntrepriseAssignment, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/enterprise-assignments/operation/${record.id ? record.id : record._id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('updateOperation() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=category,staff,pmtPartner,partner,vehicle,asset,issuedBy,approvedBy,returnedBy`;
          queryString += `,rejectedBy,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }
}
