import { SalaryGrade, Staff } from "../people";
import { Transfer } from "./transfer";

export class Promotion {
    _id: string;
    id: string;
    code: string;
    description: string;
    staff: Staff;
    salaryGradeFrom: SalaryGrade;
    salaryGradeTo: SalaryGrade;
    transfer: Transfer;
    promotionBy: Staff;
    promotionDate: Date;
    remark: string;
    status: string;
    isDemotion: Boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        for (const f in fields) {
            this[f] = fields[f];
            }
        }
}

export interface Promotion {
    _id: string;
    id: string;
    code: string;
    description: string;
    staff: Staff;
    salaryGradeFrom: SalaryGrade;
    salaryGradeTo: SalaryGrade;
    transfer: Transfer;
    promotionBy: Staff;
    promotionDate: Date;
    remark: string;
    status: string;
    isDemotion: Boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

}