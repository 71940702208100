import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { PmlDriver, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';

@Injectable()

export class PmlDrivers {
  pmlDrivers: PmlDriver[] = [];
  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=pmlRoutings,pmlParcels,createdBy,updatedBy`;
    queryString += `,pmlTerminalFrom,pmlTerminalTo,pmlTerminalStore,recentPartner,recentStaff`;
    this.recordRetrieve(queryString).then(res => { this.pmlDrivers = res.payload; console.log(res) });
  }
  query(params?: any) {
    if (!params) {
      return this.pmlDrivers;
    }
    return this.pmlDrivers.filter((item) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = item[key];
          if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });
  }
  add(record: PmlDriver) {
    this.pmlDrivers.push(record);
  }
  delete(record: PmlDriver) {
    this.pmlDrivers.splice(this.pmlDrivers.indexOf(record), 1);
  }
  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/pml-drivers${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }
  async recordCreate(record: PmlDriver): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/pml-drivers`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmlRoutings,pmlParcles,createdBy,updatedBy`;
          queryString += `,pmlTerminalFrom,pmlTerminalTo,pmlTerminalStore,recentPartner,recentStaff`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }
  async recordUpdate(record: PmlDriver, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/pml-drivers/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmlRoutings,pmlParcel,createdBy,updatedBy`;
          queryString += `,pmlTerminalFrom,pmlTerminalTo,pmlTerminalStore,recentPartner,recentStaff`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }
  async recordPatch(record: PmlDriver): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/pml-drivers/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
  async recordDelete(record: PmlDriver): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/pml-drivers/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}