import { Account } from "./account";
import { Staff } from '../people/staff';
import { Transaction } from "./transaction";


export class Credit {
    id: string;
    code: string;
    trxref: string;
    transaction: Transaction;
    amount: Number;
    gateway: string;
    customer: Account;
    paymentMethod: "POS" | "CASH" | "CHEQUE" |  "TRANSFER" |  "USSD" | "WALLET";
    paymentGateway: "FLUTTERWAVE" | "INTERSWITCH" | "UNIONBANK" | "PAYSTACK" | "STRIPE" | "PAYPAL" | "GOOGLE_WALLET" | "PEACE_WALLET";
    paymentStatus: "PENDING" | "SUCCESSFUL" | "FAIL";
    description: string;
    status: "PENDING" | "SUCCESSFUL" | "FAIL";
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Credit {
    id: string;
    code: string;
    trxref: string;
    transaction: Transaction;
    amount: Number;
    gateway: string;
    customer: Account;
    paymentMethod: "POS" | "CASH" | "CHEQUE" |  "TRANSFER" |  "USSD" | "WALLET";
    paymentGateway: "FLUTTERWAVE" | "INTERSWITCH" | "UNIONBANK" | "PAYSTACK" | "STRIPE" | "PAYPAL" | "GOOGLE_WALLET" | "PEACE_WALLET";
    paymentStatus: "PENDING" | "SUCCESSFUL" | "FAIL";
    description: string;
    status: "PENDING" | "SUCCESSFUL" | "FAIL";
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;
}
