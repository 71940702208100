import { Staff } from '../people/staff';
import { Partner } from '../people/partner';
import { Vehicle } from '../factory/vehicle';
import { Task } from '../general/task';
import { Asset } from './asset';
import { Category } from '../general/category';

export class Assignment {
    id: string;
    code: string;
    type: 'VEHICLE'|'INVENTORY' ;
    category: Category;
    custodian: 'STAFF'|'PARTNER';
    staff: Staff;
    partner: Partner;
    requestDate: Date; // desired date to receive to have the request granted
    task: Task;
    vehicle: Vehicle;
    asset: Asset;
    status: 'PENDING'|'APPROVED'|'REJECTED'|'ISSUED'|'RETURNED'|'AUDITED'|'CLOSED';
    isReturnable: boolean;
    purpose: string;
    expectedDate: Date;
    approvedDate: Date;
    approvedBy: Staff;
    rejectedDate: Date;
    rejectedBy: Staff;
    issuedDate: Date;
    issuedBy: Staff;
    returnedDate: Date;
    returnedBy: Staff;
    remark: Staff;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Assignment {
    id: string;
    _id: string;
    code: string;
    type: 'VEHICLE'|'INVENTORY' ;
    category: Category;
    custodian: 'STAFF'|'PARTNER';
    staff: Staff;
    partner: Partner;
    pmtPartner: Partner;
    requestDate: Date; // desired date to receive to have the request granted
    task: Task;
    vehicle: Vehicle;
    asset: Asset;
    status: 'PENDING'|'APPROVED'|'REJECTED'|'ISSUED'|'RETURNED'|'AUDITED'|'CLOSED';
    isReturnable: boolean;
    purpose: string;
    expectedDate: Date;
    approvedDate: Date;
    approvedBy: Staff;
    rejectedDate: Date;
    rejectedBy: Staff;
    issuedDate: Date;
    issuedBy: Staff;
    returnedDate: Date;
    returnedBy: Staff;
    remark: Staff;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
