import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {AuthService} from '../../services';
import {Staff} from '../../models/people';
import {getFullname} from '../../helpers';

let misc: any = {
    sidebar_mini_active: true
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'tech_tablet'
  },
   {
    path: '/teams',
    title: 'Team',
    type: 'link',
    icontype: 'users_single-02'
  },
  {
    path: '/tasks',
    title: 'Task',
    type: 'link',
    icontype: 'users_single-02'
  },
  {
    path: '/category',
    title: 'Category',
    type: 'link',
    icontype: 'files_paper'
  },
  {
    path: '/sms',
    title: 'SMS',
    type: 'link',
    icontype: 'travel_istanbul'
  },
  {
    path: '/memo',
    title: 'Memo',
    type: 'link',
    icontype: 'files_paper'
  },
  {
    path: '/notification',
    title: 'Notification',
    type: 'link',
    icontype: 'files_paper'
  },
  {
    path: '/mail',
    title: 'Mail',
    type: 'link',
    icontype: 'files_paper'
  },

  {
    path: '/ecommerce',
    title: 'E-Commerce',
    type: 'link',
    icontype: 'shopping_tag-content'
  },

  {
    path: '/spare-product',
    title: 'Spares Product',
    type: 'link',
    icontype: 'design_image'
  },
  // {
  //   path: '/sync',
  //   title: 'Synchronization',
  //   type: 'link',
  //   icontype: 'design_image'
  // },
  // {
  //   path: '/memo',
  //   title: 'Memo',
  //   type: 'link',
  //   icontype: 'files_paper'
  // },
  {
    path: '/vouchers',
    title: 'Voucher',
    type: 'link',
    icontype: 'business_money-coins'
  },
  {
    path: '/promotions',
    title: 'Promotion',
    type: 'link',
    icontype: 'files_paper'
  },
  {
    path: '/transfers',
    title: 'Transfer',
    type: 'link',
    icontype: 'files_paper'
  },
  {
    path: '/offences',
    title: 'Offence',
    type: 'link',
    icontype: 'design_app'
  },
  {
    path: '/calendars',
    title: 'Calendar',
    type: 'link',
    icontype: 'ui-1_calendar-60'
  },
  {
    path: '/user-profile',
    title: 'User Profile',
    type: 'link',
    icontype: 'business_badge'
  }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;
  public user: Staff;
  getFullName = getFullname;

  constructor(private toastr: ToastrService,
              private authService: AuthService) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  sleep(milliseconds) {
    let start = new Date().getTime();
    for (let i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }
  myFunc(event, menuitem) {
    event.preventDefault();
    event.stopPropagation();
    this.sleep(10);
    if (menuitem.isCollapsing === undefined) {
      menuitem.isCollapsing = true;

      // menuitem.isCollapsed = !menuitem.isCollapsed;

      let element = event.target;
      while (
        element.getAttribute('data-toggle') != 'collapse' &&
        element != document.getElementsByTagName('html')[0]
      ) {
        element = element.parentNode;
      }
      element = element.parentNode.children[1];

      if (
        element.classList.contains('collapse') &&
        !element.classList.contains('show')
      ) {
        element.classList = 'before-collapsing';
        let style = element.scrollHeight;

        element.classList = 'collapsing';
        setTimeout(function() {
          element.setAttribute('style', 'height:' + style + 'px');
        }, 1);
        setTimeout(function() {
          element.classList = 'collapse show';
          element.removeAttribute('style');
          menuitem.isCollapsing = undefined;
        }, 350);
      } else {
        let style = element.scrollHeight;
        setTimeout(function() {
          element.setAttribute('style', 'height:' + (style + 20) + 'px');
        }, 3);
        setTimeout(function() {
          element.classList = 'collapsing';
        }, 3);
        setTimeout(function() {
          element.removeAttribute('style');
        }, 20);
        setTimeout(function() {
          element.classList = 'collapse';
          menuitem.isCollapsing = undefined;
        }, 400);
      }
    }
  }
  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('sidebar-mini')) {
        misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
        body.classList.remove('sidebar-mini');
        misc.sidebar_mini_active = false;
        this.showSidebarMessage('Sidebar mini deactivated...');
    } else {
            body.classList.add('sidebar-mini');
            this.showSidebarMessage('Sidebar mini activated...');
            misc.sidebar_mini_active = true;
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function() {
        window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function() {
        clearInterval(simulateWindowResize);
    }, 1000);
  }

  showSidebarMessage(message) {
    this.toastr.show(
      '<span class="now-ui-icons ui-1_bell-53"></span>', message,
      {
        timeOut: 4000,
        closeButton: true,
        enableHtml: true,
        toastClass: 'alert alert-danger alert-with-icon',
        positionClass: 'toast-top-right'
      }
    );
  }
}
