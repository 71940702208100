import { Staff } from '../people/staff';


export class EwalletSetting {
    id: string;
    name: string;
    value: string;
    category: string;
    description: string;
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface EwalletSetting {
    id: string;
    name: string;
    value: string;
    category: string;
    description: string;
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;
}
