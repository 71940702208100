import { PmlTerminal } from './pml-terminal';
import { Staff } from '../people/staff';

export class PmlRoute {

    id: string;
    name: string;
    PmlTerminalFrom: PmlTerminal;
    PmlTerminalTo: PmlTerminal;
    type: 'HIGHWAY' | 'SUBHIGHWAY' | 'LOCAL';
    fuelLitresBus: number;
    fuelLitresTruck: number;
    distance?: number;
    allowanceBusDriver: number;
    allowanceTruckDriver: number;
    durationBus: number; //* minutes
    durationTruck: number; //* minutes
    tripBus: number;
    tripTruck: number;
    billingTier: number;
    isActive: boolean;

    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    deleted?: boolean;
    deletedAt?: Date;
    deletedBy?: Staff;
    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface PmlRoute {
    [prop: string]: any;
    id: string;
    name: string;
    PmlTerminalFrom: PmlTerminal;
    PmlTerminalTo: PmlTerminal;
    type: 'HIGHWAY' | 'SUBHIGHWAY' | 'LOCAL';
    fuelLitresBus: number;
    fuelLitresTruck: number;
    distance?: number;
    allowanceBusDriver: number;
    allowanceTruckDriver: number;
    durationBus: number; //* minutes
    durationTruck: number; //* minutes
    tripBus: number;
    tripTruck: number;
    billingTier: number;
    isActive: boolean;

    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    deleted?: boolean;
    deletedAt?: Date;
    deletedBy?: Staff;
}

