import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map, tap } from 'rxjs/operators';
import { EnvService } from './env.service';
import { LoginResponse, Staff, ApiResponse } from '../models';
import { getLocalStorage, setLocalStorage, removeLocalStorage, cleanObject } from '../helpers';
import { NotificationService } from './notification.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  isLoggedIn = false;
  token = null;
  depth = 0;
  user: Staff;

  constructor(private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private apiService: ApiService,
    private env: EnvService,
    private notify: NotificationService) {
      this.user = !this.user ? this.getUser() : this.user;
     }

     async postLogin(data, element): Promise<LoginResponse> {
      const payload = cleanObject(data);
      const response = this.http.post(this.env.API_URL + '/erp/staff/login', payload)
      .pipe(tap(async (res: LoginResponse) => {
        element.removeClass('running');
        if (res.success) {
          this.showNotification(`Login successful<br/>Welcome! ERP General`);
          const { user, token } = res.payload;
          if (setLocalStorage('token', token, null)) {
            } else {
            console.error('Error storing record token');
            }
            // get user info
            const userInfo = this.http.get(this.env.API_URL + '/erp/staff/me').pipe(tap((res: ApiResponse) => {
              if (res.success) {
                  setLocalStorage('user', res.payload, null);
              }
            }));
            await userInfo.toPromise();
          const goingTo = payload.otp ? '/forgot-password' : '/dashboard';
          this.token = token;
          this.isLoggedIn = true;
          // const intendURL = getLocalStorage('intendURL') === null ? goingTo : getLocalStorage('intendURL');
          this.router.navigate([goingTo]);
        } else {
          this.showNotification(res.message);
          this.token = null;
          this.isLoggedIn = false;
        }
        }, (err) => {
        element.removeClass('running');
        this.showNotification(err);
        this.token = null;
        this.isLoggedIn = false;
      }));
        return await response.toPromise();
    }
  
    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
      const url = `${this.env.API_URL}/erp/erp-general${queryString}`;
      const proRes = this.apiService.getApi(url).pipe(
          map((res: ApiResponse) => res));
      return await proRes.toPromise();
  }
  
  
    showNotification(message) {
      this.toastr.show(`<span class="fa ui-1_bell-53"></span> <b>${message}</b>`, '', {
        timeOut: 8000,
        closeButton: true,
        enableHtml: true,
        toastClass: 'alert alert-primary alert-with-icon',
      });
    }
  
    register(data: any) {
      const payload = cleanObject(data);
      return this.http.post(this.env.API_URL + '/staff', payload);
    }
  
    getUser(): Staff {
      if(!!this.user) return this.user;
      return getLocalStorage('user');
    }
  
    public async getToken(): Promise<any> {
      try {
        const token = await getLocalStorage('token');
        if (token != null) {
          this.token = token;
          this.isLoggedIn = true;
        } else {
          this.token = null;
          this.isLoggedIn = false;
        }
        return token;
      } catch (e) {
        console.log(e);
        alert(JSON.stringify(e));
        return null;
      }
    }
  
    userLogOut(): void {
      this.isLoggedIn = false;
      delete this.token;
      removeLocalStorage('user');
      removeLocalStorage('token');
      this.router.navigate(['/login']);
    }
  
    isAuthenticated() {
      return (!!getLocalStorage('user')) ? true : false;
    }
  
}
