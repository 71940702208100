import { Staff } from '../people';
import { Customer } from '../crm';
import { EcommerceCustomer } from '../ecommerce';


export class Account {
    id: string;
    wallet: string;
    pin: string;
    customer: Customer;
    ecommerceId: EcommerceCustomer;
    type: 'DEBIT' | 'CREDIT' | 'BOTH';
    accountName: string;
    accountNumber: string;
    balance: string;
    royalty: string;
    isActive: boolean;
    isOverdraft: boolean;
    overdraft: number;
    remark: string;
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Account {
    id: string;
    wallet: string;
    pin: string;
    customer: Customer;
    ecommerceId: EcommerceCustomer;
    type: 'DEBIT' | 'CREDIT' | 'BOTH';
    accountName: string;
    accountNumber: string;
    balance: string;
    royalty: string;
    isActive: boolean;
    isOverdraft: boolean;
    overdraft: number;
    remark: string;
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;
}
