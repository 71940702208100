import { Vehicle } from '../factory/vehicle';
import { Rating } from '../crm/rating';
import { State } from '../location/state';
import { Staff } from '../people/staff';
import { County } from '../location/county';
import { PmlRegion } from '../pml';
import { Sales } from '../../providers';
import { PmtMaintenance } from '../workshop';
import { PmtSchedule } from '../pmt';
import { PmlPackage } from '../pml';
import { Assignment } from '../finance';

export class Partner {
    id: string;
    serial?: string;
    title: string;
    type: string;
    assignments: Assignment[];
    currentVehicle?: Vehicle;
    isAssignedVehicle?: boolean;
    driverLicence: string;
    surname: string;
    otherName: string;
    gender?: string;
    birthDate: string;
    maritalStatus?: string;
    address: string;
    state?: State;
    county?: County;
    country?: string;
    region?: PmlRegion;
    password?: string;
    otp?: string;
    otpCount?: number;
    otpAccess: boolean;
    email?: string;
    phone: string;
    phoneHome?: string;
    kin?: string;
    kinPhone?: string;
    kinAddress?: string;
    guarantor1?: string;
    guarantor1Address?: string;
    guarantor1Phone?: string;
    guarantor2?: string;
    guarantor2Address?: string;
    guarantor2Phone?: string;
    photo?: string;
    qualification?: string;
    institution?: string;
    notice?: string;
    ratings?: Array<Rating>;
    remark?: string;
    bonus?: string;
    bank?: any;
    bankAccountNumber?: string;
    bankAccountName?: string;
    isDocumentComplete?: boolean;
    status: string;
    approvedBy: Staff;
    approvedDate?: Date;
    verifiedBy: Staff;
    verifiedDate: Date;
    wallet: number;
    sales: Sales[];
    maintenances: PmtMaintenance[];
    pmtSchedules: PmtSchedule[];
    pmlPackages: PmlPackage[];
    employment?: string;
    disengagedDate?: Date;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}


export interface Partner {
    id: string;
    _id: string;
    serial?: string;
    title: string;
    type: string;
    assignments: Assignment[];
    currentVehicle?: Vehicle;
    isAssignedVehicle?: boolean;
    driverLicence: string;
    surname: string;
    otherName: string;
    gender?: string;
    birthDate: string;
    maritalStatus?: string;
    address: string;
    state?: State;
    county?: County;
    country?: string;
    region?: PmlRegion;
    password?: string;
    otp?: string;
    otpCount?: number;
    otpAccess: boolean;
    email?: string;
    phone: string;
    phoneHome?: string;
    kin?: string;
    kinPhone?: string;
    kinAddress?: string;
    guarantor1?: string;
    guarantor1Address?: string;
    guarantor1Phone?: string;
    guarantor2?: string;
    guarantor2Address?: string;
    guarantor2Phone?: string;
    photo?: string;
    qualification?: string;
    institution?: string;
    notice?: string;
    ratings?: Array<Rating>;
    remark?: string;
    bonus?: string;
    bank?: any;
    bankAccountNumber?: string;
    bankAccountName?: string;
    isDocumentComplete?: boolean;
    status: string;
    approvedBy: Staff;
    approvedDate?: Date;
    verifiedBy: Staff;
    verifiedDate: Date;
    wallet: number;
    sales: Sales[];
    maintenances: PmtMaintenance[];
    pmtSchedules: PmtSchedule[];
    pmlPackages: PmlPackage[];
    employment?: string;
    disengagedDate?: Date;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

