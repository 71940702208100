import { Staff } from '../people/staff';
import { BankAccount } from './bank-account';
import { Terminal } from '../location/terminal';

export class BankTransaction {
    
    id: string;
    type: string;
    code: string;
    bankName: String;
    bankCode: String;
    bankAccount: BankAccount;
    subsidiary: String;
    terminal: Terminal;
    branchId: number;
	channel: string;
	tranDate: Date;
	tranCode: string;
	amount: string;
	currency: string;
	destAccountNo: string;
	balance: number;
	originBank: string;
	narration: string;
	tranType: string;
	valueDate: Date;
	businessId: number;
	transactionRef: string;
    branchName: string;
	createdAt?: Date;
    updatedAt?: Date;
    

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface BankTransaction {
   // [prop: string]: any;
   id: string;
   type: string;
   code: string;
   bankName: String;
   bankCode: String;
   bankAccount: BankAccount;
   subsidiary: String;
   terminal: Terminal;
   branchId: number;
   channel: string;
   tranDate: Date;
   tranCode: string;
   amount: string;
   currency: string;
   destAccountNo: string;
   balance: number;
   originBank: string;
   narration: string;
   tranType: string;
   valueDate: Date;
   businessId: number;
   transactionRef: string;
   branchName: string;
   createdAt?: Date;
   updatedAt?: Date;
}

