import { Vehicle } from "../factory";
import { Asset } from "../finance";
import { Category, Task } from "../general";
import { Entreprise, Staff } from "../people";

export class EntrepriseAssignment {
    
    id : string;
   _id:string;
    code: string;
    type: string;
    subsidiary:string;
    category: Category;
    enterprise: Entreprise;
    requestDate: Date;
    task: Task;
    vehicles: Vehicle[];
    asset: Asset;
    status: string;
    issuedDate: Date;
    issuedBy: Staff;
    isReturnable: boolean;
    expectedDate: Date;
    returnedDate: Date;
    returnedBy: Staff;
    approvedDate: Date;
    approvedBy: Staff;
    rejectedDate: Date;
    rejectedBy: Staff;
    purpose: string;
    remark: string;
    createdBy: Staff;
    updatedBy: Staff;
    auditedBy: Staff;
    auditedDate: Date;
    auditedRemark: string;
    deleted: boolean;
    deletedAt: Date;
    deletedBy: Staff;

    constructor(fields: any) {
        for (const f in fields) {
            this[f] = fields[f];
        }
    }
}



