import { Staff } from '../people/staff';
import { Partner } from '../people/partner';
import { Terminal } from '../location/terminal';
import { Vehicle } from '../factory/vehicle';
import { Customer } from './customer';
import { Blog } from './blog';

export class BlogRating {
  id: string;
  star: number;
  subject: string;
  staff: Staff;
  partner: Partner;
  customer: Customer;
  terminal: Terminal;
  vehicle: Vehicle;
  blogpost : Blog;
  review: string;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
        this[f] = fields[f];
    }
}

}

export interface BlogRating {
[prop: string]: any;

      id: string;
      star: number;
      subject: string;
      staff: Staff;
      partner: Partner;
      customer: Customer;
      terminal: Terminal;
      vehicle: Vehicle;
      blogpost : Blog;
      review: string;
      createdBy?: Staff;
      createdAt?: Date;
      updatedBy?: Staff;
      updatedAt?: Date;

}

