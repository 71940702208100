import { Staff } from '../people/staff';
import { Voucher } from './voucher';
import { PayrollDetail } from './payroll-detail';
import { Terminal } from '../location';

export class Payroll {
    id: string;
    period: Date;	
    code: string;
    subsidiary: string; 
    terminal: Terminal;	
    voucher: Voucher; 	
    payrollDetails: PayrollDetail[]; 	
    total: Number;	
    payStart: Date; 	
    remark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Payroll {
    // [prop: string]: any;
    id: string;
    period: Date;	
    code: string;
    subsidiary: string; 
    terminal: Terminal;		
    voucher: Voucher; 	
    payrollDetails: PayrollDetail[]; 	
    total: Number;	
    payStart: Date; 	
    remark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

