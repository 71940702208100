import { Customer } from '../crm/customer';
import { PmlPackage } from './pml-package';
import { PmlTerminal } from './pml-terminal';
import { Staff } from '../people/staff';
import { PmtSchedule } from '../pmt/pmt-schedule';
import { PmlRouting } from './pml-routing';
import { PmlPickup } from './pml-pickup';

export class PmlParcel {
  id: string;
  code: string;
  name: string;
  pmlRoutings: Array<PmlRouting>;
  pmlPackage: PmlPackage;
  pmtSchedule: PmtSchedule;
  sender: Customer;
  recipient: Customer;
  urgency: number;
  distance: number;
  mass: number;
  volume: number;
  worth: number;
  isFragile: boolean;
  isPerishable: boolean;
  isCombustible: boolean;
  isOdiferous: boolean;
  isLiquid: boolean;
  isUnique: boolean;
  description: string;
  pmlPickup: PmlPickup;
  pmlTerminalFrom: PmlTerminal;
  pmlTerminalTo: PmlTerminal;
  pmlTerminalStore: PmlTerminal;
  travelHour: number;
  departureDate: Date;
  expectedDate: Date;
  billingType: 'PRE_PAID'|'POST_PAID'|'DEDICATED';
  costEstimate: number;
  costPayable: number;
  paymentMethod: 'GATEWAY'|'POS'|'CASH'|'CHEQUE'|'TRANSFER'|'USSD';
  paymentGateway: 'UNIONBANK'|'PAYSTACK';
  paymentStatus: 'PENDING'|'SUCCESSFUL'|'FAIL';
  deliveryDate: Date;
  deliveryType: 'HOME'|'TERMINAL';
  deliveryAddress: string;
  deliveredBy: Staff;
 // deliveryAgent: Staff;
  deliveryStatus: 'PENDING'|'STORED'|'TRANSIT'|'DELIVERED'|'DISCHARGED';
  routing: string;
  routingStatus: "STORE" | "TRANSIT";
  packageedDate: Date;
  packageedBy: Staff;
  shippedDate: Date;
  shippedBy: Staff;
  dispatchedDate: Date;
  dispatchedBy: Staff;
  arrivedDate: Date;
  arrivedBy: Staff;
  deliveredDate: Date;
  identification: string;
  remark: string;
  recipientConfirm: boolean;
  recipientConfirmDate: Date;
  recipientConfirmRemark: string;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;
 

  constructor(fields: any) {
    for (const f in fields) {
      this[f] = fields[f];
    }
  }

}

export interface PmlParcel {
  id: string;
  code: string;
  name: string;
  pmlRoutings: Array<PmlRouting>;
  pmlPackage: PmlPackage;
  pmtSchedule: PmtSchedule;
  sender: Customer;
  recipient: Customer;
  urgency: number;
  distance: number;
  mass: number;
  volume: number;
  worth: number;
  isFragile: boolean;
  isPerishable: boolean;
  isCombustible: boolean;
  isOdiferous: boolean;
  isLiquid: boolean;
  isUnique: boolean;
  description: string;
  pmlPickup: PmlPickup;
  pmlTerminalFrom: PmlTerminal;
  pmlTerminalTo: PmlTerminal;
  pmlTerminalStore: PmlTerminal;
  travelHour: number;
  departureDate: Date;
  expectedDate: Date;
  billingType: 'PRE_PAID'|'POST_PAID'|'DEDICATED';
  costEstimate: number;
  costPayable: number;
  paymentMethod: 'GATEWAY'|'POS'|'CASH'|'CHEQUE'|'TRANSFER'|'USSD';
  paymentGateway: 'UNIONBANK'|'PAYSTACK';
  paymentStatus: 'PENDING'|'SUCCESSFUL'|'FAIL';
  deliveryDate: Date;
  deliveryType: 'HOME'|'TERMINAL';
  deliveryAddress: string;
  deliveredBy: Staff;
 // deliveryAgent: Staff;
  deliveryStatus: 'PENDING'|'STORED'|'TRANSIT'|'DELIVERED'|'DISCHARGED';
  routing: string;
  routingStatus: "STORE" | "TRANSIT";
  packageedDate: Date;
  packageedBy: Staff;
  shippedDate: Date;
  shippedBy: Staff;
  dispatchedDate: Date;
  dispatchedBy: Staff;
  arrivedDate: Date;
  arrivedBy: Staff;
  deliveredDate: Date;
  identification: string;
  remark: string;
  recipientConfirm: boolean;
  recipientConfirmDate: Date;
  recipientConfirmRemark: string;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;
 
}
