import { Permission } from "./permission";
import { Staff } from "./staff";

export class Role {
    id: string;
    name: string;
    description: string;
    permissions: Array<Permission>;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Role {
    id: string;
    name: string;
    description: string;
    permissions: Array<Permission>;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
