

export class Receipt {
  id?: string;
  receiptType: 'INVOICE'|'SLIP'|'TICKET'|'WAYBILL';
  code: string;
  dateIssued: Date;
  bearer: string;
  pmtRoute: string;
  seatQuantity: number;
  seatPosition?: string;
  vehicleNumber: string;
  transportFee?: number;
  totalPayout?: number;
  tax?: number;
  fareTotal?: number;
  fuelAmount?: number;
  partnerAllowance?: number;
  dtoRepayment?: number;
  dtoServiceCharge?: number;
  dtoMaintenance?: number;
  partnerReceivable?: number;

  constructor(fields: any) {
      // Quick and dirty extend/assign fields to this model
      for (const f in fields) {
          // @ts-ignore
          this[f] = fields[f];
      }
  }

}

export interface Receipt {
  // [prop: string]: any;
  id?: string;
  receiptType: 'INVOICE'|'SLIP'|'TICKET'|'WAYBILL';
  code: string;
  dateIssued: Date;
  bearer: string;
  pmtRoute: string;
  seatQuantity: number;
  seatPosition?: string;
  vehicleNumber: string;
  transportFee?: number;
  totalPayout?: number;
  tax?: number;
  fareTotal?: number;
  fuelAmount?: number;
  partnerAllowance?: number;
  dtoRepayment?: number;
  dtoServiceCharge?: number;
  dtoMaintenance?: number;
  partnerReceivable?: number;
}

