
import { Staff } from '../people/staff';
import { Office } from '../people/office';
import { Terminal } from '../location/terminal';
import { AccountHeading } from './account-heading'
import { Category } from '../general/category';

export class Budget {
    id: string;
    code: string;
    category: Category;
    type: string;
    name: string;
    description: string;
    subsidiary: String;
    year: number;
    amount: number;
    office: Office;
    terminal: Terminal;
    accountHeading: AccountHeading;
    beginDate: Date;
    endDate: Date;
    status: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // tslint:disable-next-line: forin
            for (const f in fields) {
                this[f] = fields[f];
            }
        }
    }

    export interface Budget {
        id: string;
        code: string;
        category: Category;
        type: string;
        name: string;
        description: string;
        subsidiary: String;
        year: number;
        amount: number;
        office: Office;
        terminal: Terminal;
        accountHeading: AccountHeading;
        beginDate: Date;
        endDate: Date;
        status: string;
        createdBy?: Staff;
        createdAt?: Date;
        updatedBy?: Staff;
        updatedAt?: Date;
    }
