
import { Staff } from '../people/staff';

export class EcommerceStore {
    id: string;
    code: string;
    name: string;
    address: string;
    keeper: Staff;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
    // tslint:disable-next-line: forin
        for (const f in fields) {
            this[f] = fields[f];
        }
    }
}

export interface EcommerceStore {
    id: string;
    code: string;
    name: string;
    address: string;
    keeper: Staff;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
