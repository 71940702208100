import { Product } from '../ecommerce';
import { Staff } from '../people/staff';

export class Fault {
    id: string;
    name: string;
    manhour: number;
    spares: Product[];
    diagnosis: string; // comment: "signs and symptoms"
    repair: string;    // comment: "treatment"
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Fault {
    id: string;
    name: string;
    manhour: number;
    spares: Product[];
    diagnosis: string; // comment: "signs and symptoms"
    repair: string;    // comment: "treatment"
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
