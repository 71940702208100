import { Collection } from '.';
import { Staff } from '../people/staff';

export class Category {
    id: string;
    code: string;
    name: string;
    description: string;
    table: Collection;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        // tslint:disable-next-line: forin
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Category {
    id: string;
    code: string;
    name: string;
    description: string;
    table: Collection;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
