import { Staff } from '../people/staff';
import { Sale } from '../ecommerce/sale';
import { Terminal } from '../location/terminal';
import { Cart } from '../ecommerce/cart';
import { Rating } from '../crm/rating';
import { Product } from '.';
import { Category } from '../general';

export class Merchant {
    id: string;
    code: string;
    title: string;
    surname: string;
    otherName: string;
    gender: string;
    birthDate: Date;
    phone: string;
    email: string;
    password: string;
    otp: string;
    otpCount: number;
    otpAccess: boolean;
    contactPerson: string;
    contactPersonPhone: string;
    products: Product[];
    photo: string;
    address: string;
    country: string;
    website: string;
    isEngrClient: boolean;
    isPmtClient?: boolean;
    isPmlClient: boolean;
    isPetClient: boolean;
    isShopClient: boolean;
    isTenant: boolean;
    company: string;
    industry: string;
    state: string;
    county: string;
    isStaff: boolean;
    wallet: number;
    cart: Cart;
    terminal: Terminal;
    ratings: Rating[];
    sales: Sale[];
    category: Category;
    isPhoneVerified: boolean;
    isEmailVerified: boolean;
    businessName: string;
    businessLogo: string;
    businessBanner: string;
    businessEmail: string;
    businessPhone: string;
    businessAddress: string;
    businessSlogan: string;
    bankName: string;
    bankAccountNumber: string;
    bankAccountName: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
    skype?: string;
    linkedin?: string;
    facebook?: string;
    instagram?: string;
    twitter?: string;
    youtube?: string;
    points?: number;
    remark?: string;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Merchant {
    id: string;
    code: string;
    title: string;
    surname: string;
    otherName: string;
    gender: string;
    birthDate: Date;
    phone: string;
    email: string;
    password: string;
    otp: string;
    otpCount: number;
    otpAccess: boolean;
    contactPerson: string;
    contactPersonPhone: string;
    products: Product[];
    photo: string;
    address: string;
    country: string;
    website: string;
    isEngrClient: boolean;
    isPmtClient?: boolean;
    isPmlClient: boolean;
    isPetClient: boolean;
    isShopClient: boolean;
    isTenant: boolean;
    company: string;
    industry: string;
    state: string;
    county: string;
    isStaff: boolean;
    wallet: number;
    cart: Cart;
    terminal: Terminal;
    ratings: Rating[];
    sales: Sale[];
    category: Category;
    isPhoneVerified: boolean;
    isEmailVerified: boolean;
    businessName: string;
    businessLogo: string;
    businessBanner: string;
    businessEmail: string;
    businessPhone: string;
    businessAddress: string;
    businessSlogan: string;
    bankName: string;
    bankAccountNumber: string;
    bankAccountName: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
    skype?: string;
    linkedin?: string;
    facebook?: string;
    instagram?: string;
    twitter?: string;
    youtube?: string;
    points?: number;
    remark?: string;
}
