import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import {LoginComponent} from './pages/login/login.component';
import {LoginOtpComponent} from './pages/login-otp/login-otp.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {AuthGuard} from './services';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login-otp',
    component: LoginOtpComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './pages/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'promotions',
        loadChildren: './pages/promotion/promotion.module#PromotionModule'
      },
      {
        path: 'transfers',
        loadChildren: './pages/transfer/transfer.module#TransferModule'
      },
      {
        path: 'offences',
        loadChildren: './pages/offence/offence.module#OffenceModule'
      },

      {
        path: 'teams',
        loadChildren: './pages/team/team.module#TeamModule'
      },

      {
        path: 'memo',
        loadChildren: './pages/memo/memo.module#MemoModule'
      },

      {
        path: 'notification',
        loadChildren: './pages/notification/notification.module#NotificationModule'
      },
      {
        path: 'category',
        loadChildren: './pages/category/category.module#CategoryModule'
      },

      {
        path: 'tasks',
        loadChildren: './pages/task/task.module#TaskModule'
      },
      {
        path: 'sms',
        loadChildren: './pages/sms/sms.module#SmsModule',
      },

      {
        path: 'mail',
        loadChildren: './pages/mail/mail.module#MailModule',
      },

      {
        path: 'spare-product',
        loadChildren: './pages/spares-product/spares-product.module#SparesProductModule',
      },

      {
        path: 'sync',
        loadChildren: './pages/sync/sync.module#SyncModule',
      },
      {
        path: 'components',
        loadChildren:
          './pages/components/components.module#ComponentsPageModule'
      },
      {
        path: 'forms',
        loadChildren: './pages/forms/forms.module#Forms'
      },
      {
        path: 'tables',
        loadChildren: './pages/tables/tables.module#TablesModule'
      },
      {
        path: 'maps',
        loadChildren: './pages/maps/maps.module#MapsModule'
      },
      {
        path: 'widgets',
        loadChildren: './pages/widgets/widgets.module#WidgetsModule'
      },
      {
        path: 'charts',
        loadChildren: './pages/charts/charts.module#ChartsModule'
      },
      {
        path: 'calendars',
        loadChildren: './pages/calendar/calendar.module#CalendarModule'
      },
      {
        path: 'vouchers',
        loadChildren: './pages/voucher/voucher.module#VoucherModule'
      },
      {
        path: 'user-profile',
        loadChildren: './pages/user-profile/user-profile.module#UserProfileModule',
      },
      {
        path: '',
        loadChildren:
          './pages/pages/user-profile/user-profile.module#UserProfileModule'
      },
      {
        path: '',
        loadChildren: './pages/pages/timeline/timeline.module#TimelineModule'
      },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'pages',
        loadChildren: './pages/pages/pages.module#PagesModule'
      }
    ]
  }
];
