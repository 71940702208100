import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { City, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Cities {

  cities: City[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=name`;
    queryString += `&populate=state,terminals`;
    this.recordRetrieve(queryString).then(res => { this.cities = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.cities;
    }
    return this.cities.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: City) {
    this.cities.push(record);
  }

  delete(record: City) {
    this.cities.splice(this.cities.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/cities${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: City): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/cities`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=state,terminals`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: City, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/cities/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=state,terminals`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: City): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/cities/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: City): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/cities/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
