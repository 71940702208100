import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Customer, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Customers {

  customers: Customer[] = [];
  private queryString: string;

  constructor(private apiService: ApiService, private env: EnvService) {
    this.queryString = `?limit=1000`;
    this.queryString += `&populate=cart,terminal,ratings,saleOrders,pmtBoardings,pmlShipments`;
    this.recordRetrieve(this.queryString).then(res => { this.customers = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.customers;
    }
    return this.customers.filter((customer) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = customer[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return customer;
            } else if (field === params[key]) {
              return customer;
            }
          }
      }
      return null;
    });
  }

  add(record: Customer) {
    this.customers.push(record);
  }

  delete(record: Customer) {
    this.customers.splice(this.customers.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/crm/customers${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Customer): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/crm/customers`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          this.queryString += `&populate=cart,terminal,ratings,saleOrders,pmtBoardings,pmlShipments`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
}

async recordUpdate(record: Customer, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/crm/customers/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          this.queryString += `&populate=cart,terminal,ratings,saleOrders,pmtBoardings,pmlShipments`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
}

async recordPatch(record: Customer): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/crm/customers/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Customer): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/crm/customers/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }


  async getCustomer(prop: string, val: any): Promise<Customer> {
    console.log('\nCalling getCustomer');
    const customerObj = this.customers.find(item => item[ prop ] === val);
    if (customerObj) return customerObj;
    const query = prop === 'id' ? `?_id=${val}` : `?${prop}=${val}`;
    await this.recordRetrieve(query)
      .then((res: ApiResponse) => {
        console.log('\ngetCustomer ==>', res);
        if (res.success) return res.payload[0] || [];
      }).catch(e => e.message);
  }
}
