import { Staff } from '../people/staff';

export class Sms {
    id: string;
    sid?: string;
    sender?: string;
    recipient? : string;
    message? : string;
    direction? : 'INBOUND' | 'OUTBOUND';
    deliveryStatus? : string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Sms {
    id: string;
    sid?: string;
    sender?: string;
    recipient? : string;
    message? : string;
    direction? : 'INBOUND' | 'OUTBOUND';
    deliveryStatus? : string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
