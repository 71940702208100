import { Staff } from '../people/staff';
import { Category } from '../general';
import { Asset } from '../finance/asset';
import { Cart } from '../ecommerce/cart';

export class Supplier {
    id: string;
    type: "INDIVIDUAL" | "ORGANIZATION" | "UNKNOWN";
    title: String;
    surname: String;
    otherName: string;
    gender: "MALE" | "FEMALE";
    birthDate: Date;
    phone: String;
    phoneHome: String;
    email: String;
    password: String;
    otp: String;
    otpCount: Number;
    otpAccess: Boolean;
    contactPerson: String;
    contactPersonPhone: String;
    product: String;
    photo: String;
    subsidiary: String;
    address: String;
    country: String;
    isPhoneVerified: Boolean;
    isEmailVerified: Boolean;
    remark: String;
    suppliableAssets: Asset[];
    carts: Cart[];
    // orders: Order[];
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;


    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }
}
export interface Supplier {
    id: string;
    type: "INDIVIDUAL" | "ORGANIZATION" | "UNKNOWN";
    title: String;
    surname: String;
    otherName: string;
    gender: "MALE" | "FEMALE";
    birthDate: Date;
    phone: String;
    phoneHome: String;
    email: String;
    password: String;
    otp: String;
    otpCount: Number;
    otpAccess: Boolean;
    contactPerson: String;
    contactPersonPhone: String;
    product: String;
    photo: String;
    subsidiary: String;
    address: String;
    country: String;
    isPhoneVerified: Boolean;
    isEmailVerified: Boolean;
    remark: String;
    suppliableAssets: Asset[];
    carts: Cart[];
    // orders: Order[];
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
