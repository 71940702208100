import { Staff } from '../people/staff';

export class Contact {
    id: string;
    fullname: string;
	email: string;
	phone: string;
	subject: string;
	message: string;
	requestType: string; //'COMPLAINT'|'ENQUIRY'|'SUGGESTION';
	requestStatus: string; //'PENDING'|'ACTIVE'|'CLOSED';
	remark: string;
    hasTicket: boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
    
    constructor(fields: any) {
        // tslint:disable-next-line: forin
            for (const f in fields) {
                this[f] = fields[f];
            }
        }
    }

    export interface Contact {
        id: string;
        fullname: string;
        email: string;
        phone: string;
        subject: string;
        message: string;
        requestType: string; //'COMPLAINT'|'ENQUIRY'|'SUGGESTION';
        requestStatus: string; //'PENDING'|'ACTIVE'|'CLOSED';
        remark: string;
        hasTicket: boolean;
        createdBy?: Staff;
        createdAt?: Date;
        updatedBy?: Staff;
        updatedAt?: Date;
    }
