import { Terminal } from '../location/terminal';
import { EcommerceStore } from './store';
import { Customer } from '../crm/customer';
import { Staff } from '../people/staff';
import { Product } from './product';
import { PmlParcel } from '../pml/pml-parcel';

export class Sale {
    id: string; //
    code: string; //
    trxref: string;
    type: string;
    gateway: {currency: string};
    description: string;
    subsidiary: string;
    store: EcommerceStore;
    customer: Customer;
    amount: Number;
    discount: Number; // Amount
    tax: Number; // Amount
    transactionDetail?: object;
    items: Array<{ product: Product, quantity: number, unitPrice: Number }>;
    paymentDate: Date;
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    paymentConfirmedBy: Staff;
    paymentConfirmedDate: Date;
    orderStatus: string; // Pending|Processing|Rejected|Completed
    deliveryAddress: string;
    deliveryType: string;
    deliveryTerminal: Terminal; // The Pick-up PML terminal
    pmlParcels: PmlParcel[];
    deliveryStatus: string;
    shippingDate: Date;
    remark: string;
    auditedStatus: string;
    auditedBy: Staff;
    auditedDate: Date;
    auditedRemark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
    // tslint:disable-next-line: forin
        for (const f in fields) {
            this[f] = fields[f];
        }
    }
}

export interface Sale {
    id: string;
    code: string;
    trxref: string;
    type: string;
    gateway: {currency: string};
    description: string;
    subsidiary: string;
    store: EcommerceStore;
    customer: Customer;
    amount: Number;
    discount: Number; // Amount
    tax: Number; // Amount
    transactionDetail?: object;
    items: Array<{ product: Product, quantity: number, unitPrice: Number }>;
    paymentDate: Date;
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    paymentConfirmedBy: Staff;
    paymentConfirmedDate: Date;
    orderStatus: string; // Pending|Processing|Rejected|Completed
    deliveryAddress: string;
    deliveryType: string;
    deliveryTerminal: Terminal; // The Pick-up PML terminal
    pmlParcels: PmlParcel[];
    deliveryStatus: string;
    shippingDate: Date;
    remark: string;
    auditedStatus: string;
    auditedBy: Staff;
    auditedDate: Date;
    auditedRemark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
