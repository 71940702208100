import { Staff } from '../people/staff';
import { Supplier } from '../finance/supplier';
import { Partner } from '../people/partner';
import { Customer } from '../crm/customer';
import { Category } from './category';

export class Notification {
    id: string;
    title: string;
    user: 'STAFF'|'SUPPLIER'|'PARTNER'|'CUSTOMER';
    staff?: Staff;
    customer?: Customer;
    partner?: Partner;
    supplier?:Supplier;
    message: string;
    status: 'UNREAD'|'READ';
    category: Category;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Notification {
    id: string;
    title: string;
    user: 'STAFF'|'SUPPLIER'|'PARTNER'|'CUSTOMER';
    staff?: Staff;
    customer?: Customer;
    partner?: Partner;
    supplier?:Supplier;
    message: string;
    status: 'UNREAD'|'READ';
    category: Category;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
