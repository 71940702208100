
import { Staff } from '../people/staff';
import { PmtZone } from './pmt-zone';
import { PmtRegion } from './pmt-region';

export class PmtAllocation {
    id: string;
    pmtMin: number;
    pmtMax: number;
    regions: PmtRegion;
    zones : PmtZone;
    routeType: string;
    trackingManager: Staff;
    updatedBy: string;
    pushable: boolean;
    pullable: boolean;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface PmtAllocation {
    [prop: string]: any;

    id: string;
    pmtMin: number;
    pmtMax: number;
    regions: PmtRegion;
    zones : PmtZone;
    routeType: string;
    trackingManager: Staff;
    updatedBy: string;
    pushable: boolean;
    pullable: boolean;

}
