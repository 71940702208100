import { Customer } from '../crm/customer';
import { PmlTerminal } from './pml-terminal';
import { State } from '../location';
import { PmlZone } from './pml-zone';
import { PmlRegion } from './pml-region';
import { Partner } from '../people';
import { PmlParcel } from './pml-parcel';

export class PmlPickup {
  id: string;
  name: string;
  sender: Customer;
  description: string;
  pmlTerminal: PmlTerminal;
  latitude: number;
  longitude: number;
  state: State;
  pmlZone: PmlZone;
  pmlRegion: PmlRegion;
  address: string;
  pickupDate: Date;
  pickupBy: Partner;
  pickupStatus: string;
  pmlParcel: PmlParcel;
  remark: string;
  declinedAt: Date;
  declinedBy : Customer;
  updatedBy: string;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}

export interface PmlPickup {
  id: string;
  name: string;
  sender: Customer;
  description: string;
  pmlTerminal: PmlTerminal;
  latitude: number;
  longitude: number;
  state: State;
  pmlZone: PmlZone;
  pmlRegion: PmlRegion;
  address: string;
  pickupDate: Date;
  pickupBy: Partner;
  pickupStatus: string;
  pmlParcel: PmlParcel;
  remark: string;
  declinedAt: Date;
  declinedBy : Customer;
  updatedBy: string;

}
