import { Staff } from '../people/staff';

export class Collection {
  id: string;
  label: string;
  name: string;
  url: string;
  pullable: boolean;
  pushable: boolean;
  changes: number;
  description: string;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;

  constructor(fields: any) {
    for (const f in fields) {
        // @ts-ignore
        this[f] = fields[f];
        }
    }
}

export interface Collection {
  id: string;
  label: string;
  name: string;
  url: string;
  pullable: boolean;
  pushable: boolean;
  changes: number;
  description: string;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;
}
