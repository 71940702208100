import { Account } from "./account";
import { Staff } from '../people/staff';
import { Transaction } from "../../models";


export class Cashout {
    id: string;
    code: string;
    user: Account;
    transaction: Transaction;
    amount: Number;
    bankName: string;
    bankAccountNumber: string;
    bankAccountName: string;
    remark: string;
    status: "PENDING" | "CANCEL" | "SUCCESS";
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Cashout {
    id: string;
    code: string;
    user: Account;
    transaction: Transaction;
    amount: Number;
    bankName: string;
    bankAccountNumber: string;
    bankAccountName: string;
    remark: string;
    status: "PENDING" | "CANCEL" | "SUCCESS";
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;
}
