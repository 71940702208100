import { EcommerceStore } from './store';
import { Product } from './product';
import { Staff } from '../people/staff';

export class Stock {
    id: string;
    type: String;
    valuation: String;
    subsidiary: String;
    store: EcommerceStore;
    product: Product;
    quantity: Number;
    costPrice: Number;
    sellingPrice: Number;
    storeQuantity: Number;
    totalQuantity: Number;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }
}

export interface Stock {
    id: string;
    type: String;
    valuation: String;
    subsidiary: String;
    store: EcommerceStore;
    product: Product;
    quantity: Number;
    costPrice: Number;
    sellingPrice: Number;
    storeQuantity: Number;
    totalQuantity: Number;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
