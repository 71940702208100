import { Staff } from ".";
import { Terminal } from "../location";
import { Office } from "./office";

export class Transfer {
    id: string;
    _id: string;
    code: string;
    description: string;
    staff: Staff;
    officeFrom: Office;
    officeTo: Office;
    terminalFrom: Terminal;
    terminalTo: Terminal;
    transferedBy: Staff;
    transferedDate: Date;
    resumptionDate: Date;
    remark: string;
    status: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Transfer {
    id: string;
    _id: string;
    code: string;
    description: string;
    staff: Staff;
    officeFrom: Office;
    officeTo: Office;
    terminalFrom: Terminal;
    terminalTo: Terminal;
    transferedBy: Staff;
    transferedDate: Date;
    resumptionDate: Date;
    remark: string;
    status: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}