import { Category } from './category';
import {Merchant, Product} from '../ecommerce';
import {Rating} from '../crm';
import {Staff} from '../people';


export class Spare {
    id: string;
    merchant: Staff;
    name: string;
    code: string;
    sku: string;
    onSale: Boolean;
    model: string;
    make: string;
    type: string;
    color: string;
    grade: string;
    variants: Array<{ _id: string, product: Product, quantity: number }>;
    size: 'S' | 'L' | 'M'| 'XL'| 'SM';
    category: Category;
    measure: string; // "Carton, Dozens, etc";
    unit: string;
    dimension: string; // "(Length * Width * Height) mm";
    volume: Number; // "dm3";
    mass: Number; // "Kg";
    area: Number; // "cm2";
    warranty: string; // "1 year";
    use: string; // "Application";
    parent: Product;
    splitGain: Number; // "No of Children parts obtain from splitting";
    reorderLevel: Number;
    reorderQuantity: Number;
    slashedPrice: Number;
    costPrice: Number;
    sellingPrice: Number;
    group: string;
    groupCode: string;
    productCode: string;
    splittable: Boolean;
    depletion: Number; // "amount reduced per day";
    description: string;
    remark: string;
    images: [any];
    attributes: [any];
    ratings: Rating[];
    createdBy?: Staff;
    updatedBy?: Staff;
    deleted?: Boolean;
    deletedAt?: Date;
    deletedBy?: Staff;


    private _id: any;

    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }
}
