import {Staff} from '../people';

export class PmlPartner {
  id: string;
  serial: string;
  title: string;
  type: string;
  vehicleSerial: string;
  driverLicence: string;
  surname: string;
  otherName: string;
  region: string;// Pml Region;
  email: string;
  phone: string;
  phoneHome: string;
  createdBy: Staff;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}

export interface PmlPartner {
  id: string;
  serial: string;
  title: string;
  type: string;
  vehicleSerial: string;
  driverLicence: string;
  surname: string;
  otherName: string;
  region: string;// Pml Region;
  email: string;
  phone: string;
  phoneHome: string;
  createdBy: Staff;
}
