import { Staff } from '../people/staff';
import { Category } from './category';

export class Synchronization {
    id: string;
    table: string;
    record: string;
    foreignService: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // tslint:disable-next-line: forin
            for (const f in fields) {
                this[f] = fields[f];
            }
        }
    }

    export interface Synchronization {
        id: string;
        table: string;
        record: string;
        foreignService: string;
        createdBy?: Staff;
        createdAt?: Date;
        updatedBy?: Staff;
        updatedAt?: Date;
    }
