import { Accident } from './accident';
import { Staff } from '../people/staff';

export class AccidentVictim {
    
    id: string;
    accident: Accident;	
    fullname:	string;	
    gender:	string;	
    ageGroup:	string;	
    phone:	string;	
    type:	string;	
    situation:	string;	
    compensation:	Number;
    remark:	string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
        // @ts-ignore
        this[f] = fields[f];
    }
}

}

export interface AccidentVictim {
    d: string;
    accident: Accident;	
    fullname:	string;	
    gender:	string;	
    ageGroup:	string;	
    phone:	string;	
    type:	string;	
    situation:	string;	
    compensation:	Number;
    remark:	string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

}
