import { Staff } from '../people/staff';
import { Blog } from './blog';

export class Author {
    id: string;
    title: string;
    surname: string;
    otherName: string;
    username: string;
    blogposts: Blog[];
    phone: string;
    phoneHome: string;
    email: string;
    skype?: string;
    linkedin?: string;
    facebook?: string;
    instagram?: string;
    twitter?: string;
    youtube?: string;
    canPublish: boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Author {
    id: string;
    title: string;
    surname: string;
    otherName: string;
    username: string;
    blogposts: Blog[];
    phone: string;
    phoneHome: string;
    email: string;
    skype?: string;
    linkedin?: string;
    facebook?: string;
    instagram?: string;
    twitter?: string;
    youtube?: string;
    canPublish: boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
