import { Terminal } from '../location/terminal';
import { County } from '../location/county';
import { State } from '../location/state';
import { Staff } from '../people/staff';

export class Store {
    id: string;
    name: string;
    description: string;
    phone: string;
    email: string;
    address: string;
    zipCode: string;
    manager: string;
    stockin: number;
    stockout: number;
	subsidiary : 'PEACEGROUP'|'PET'|'CHEM'|'PRESS'|'SPARE';	
    terminal: Terminal|string;
    county: County|string;
    state: State|string;
    staffList?: Staff[];
    head?: Staff;
    country: string;
    createdAt?: Date;
    updatedAt?: Date;
    createdBy?: Staff;

    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }
}

export interface Store {
    id: string;
    name: string;
    description: string;
    phone: string;
    email: string;
    address: string;
    zipCode: string;
    manager: string;
    stockin: number;
    stockout: number;
	subsidiary : 'PEACEGROUP'|'PET'|'CHEM'|'PRESS'|'SPARE';	
    terminal: Terminal|string;
    county: County|string;
    state: State|string;
    staffList?: Staff[];
    head?: Staff;
    country: string;
    createdAt?: Date;
    updatedAt?: Date;
    createdBy?: Staff;
}
