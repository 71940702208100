import { Staff } from '../people';
import { ParkTransaction } from './park-transaction';

export class ParkBankRegister {
    id: string;
    code: string;
    amount: Number;
    depositedBy: string;
    depositedDate: Date;
    teller: string;
    recordStatus: string;
    transactions: ParkTransaction[];
    auditedStatus: string;
    auditedBy: Staff;
    auditedDate: Date;
    auditedRemark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;



    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}


