import { Customer } from '../crm/customer';
import { PmtSchedule } from './pmt-schedule';
import { PmtRoute } from './pmt-route';
import { Staff } from '../people/staff';
import { PmtTerminal } from './pmt-terminal';

export class PmtBooking {
    id: string;
    code: string;
    sessionId: string;
    serviceCode: string;
    phone: string;
    text: string;
    description: string;
    bookingType: ["USSD", "ONLINE"];
    customer: Customer;
    pmtTerminalFrom : PmtTerminal;
    seatQuantity: number;
    seatPositions: [];
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    fare: number;
    pmtRoute: PmtRoute;
    pmtSchedule: PmtSchedule;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface PmtBooking {
    id: string;
    code: string;
    sessionId: string;
    serviceCode: string;
    phone: string;
    text: string;
    description: string;
    bookingType: ["USSD", "ONLINE"];
    customer: Customer;
    pmtTerminalFrom : PmtTerminal;
    seatQuantity: number;
    seatPositions: [];
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    fare: number;
    pmtRoute: PmtRoute;
    pmtSchedule: PmtSchedule;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

