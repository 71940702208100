import { Staff } from '../people/staff';
import { Category } from '../general/category';
import { Supplier } from '../finance/supplier';
import { Rating } from '../crm/rating';

export class FactoryProduct {
    id: string;
    name : string;
	code :string; // common for all Nokia 3310
	sku :string; // uniqque for each Nokia 3310
    model: string;
    make: string;
	type:	string;	
	color	: string;	
	grade	: string;	
	variants : string;
	size :	string;	
	category :	Category;
	measure :	string;	
	unit : string;		
	dimension	: string;
	volume : number;	
	area : number;
	mass : number;
    parent: FactoryProduct;
    splitGain: number;
	reorderLevel  : number;
	reorderQuantity : number;	
	slashed : number;
	cost : number;
    selling: number;
    splittable:	boolean;
    depletion: number;
    supplier: Supplier;
	description :	string;
	subsidiary : 'PEACEGROUP'|'PET'|'CHEM'|'PRESS'|'SPARE';	
    remark: boolean;
	images:	string[];
	attributes: [{ name: string, value: string }];
    ratings: Rating[];
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface FactoryProduct {
    // [prop: string]: any;
    id: string;
    name : string;
	code :string; // common for all Nokia 3310
	sku :string; // uniqque for each Nokia 3310
    model: string;
    make: string;
	type:	string;	
	color	: string;	
	grade	: string;	
	variants : string;
	size :	string;	
	category :	Category;
	measure :	string;	
	unit : string;		
	dimension	: string;
	volume : number;	
	area : number;
	mass : number;
    parent: FactoryProduct;
    splitGain: number;
	reorderLevel  : number;
	reorderQuantity : number;	
	slashed : number;
	cost : number;
    selling: number;
    splittable:	boolean;
    depletion: number;
    supplier: Supplier;
	description :	string;
	subsidiary : 'PEACEGROUP'|'PET'|'CHEM'|'PRESS'|'SPARE';	
    remark: boolean;
	images:	string[];
	attributes: [{ name: string, value: string }];
    ratings: Rating[];
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

