import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { PmlParcel, ApiResponse, PmlTerminal, Staff } from '../../models';
import { ApiService, EnvService, AuthService } from '../../services';
import { ID } from '../../helpers';


@Injectable()
export class PmlParcels {

    pmlParcels: PmlParcel[] = [];
    today = new Date().toISOString().slice(0, 10);
    user: Staff;
    terminalId = PmlTerminal['id'];

    constructor(authService: AuthService, private apiService: ApiService, private env: EnvService) {
        this.user = authService.getUser();
        this.terminalId = this.user.terminal.id;
        let queryString = `?sort=-createdAt`;
        queryString += `&populate=createdBy,updatedBy,pmlTerminalFrom,pmlTerminalTo,pmlTerminal,sender,recipient`;
      //  queryString += `&populate=pmlRoutings,pmlPackage,pmtSchedule,pmlTerminalStore,deliveryBy,`;
        this.recordRetrieve(queryString).then(res => { this.pmlParcels = res.payload; console.log(res) });
    }

    query(params?: any) {
        if (!params) {
            return this.pmlParcels;
        }
        return this.pmlParcels.filter((item) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = item[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return item;
                    } else if (field === params[key]) {
                        return item;
                    }
                }
            }
            return null;
        });
    }

    add(record: PmlParcel) {
        this.pmlParcels.push(record);
    }

    delete(record: PmlParcel) {
        this.pmlParcels.splice(this.pmlParcels.indexOf(record), 1);
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pml/pml-parcels${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => res));
        return await proRes.toPromise();
    }


    async recordCreate(record: PmlParcel): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pml/pml-parcels`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    console.log('recordCreate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                  //  queryString += `&populate=pmlRoutings,pmlPackage,pmtSchedule,sender,recipient`;
                  //  queryString += `,pmlTerminalFrom,pmlTerminalTo,pmlTerminalStore,deliveryBy,createdBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0])
                    })
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordUpdate(record: PmlParcel, payload): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pml/pml-parcels/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    console.log('recordUpdate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                 //   queryString += `&populate=pmlRoutings,pmlPackage,pmtSchedule,sender,recipient`;
                  //  queryString += `,pmlTerminalFrom,pmlTerminalTo,pmlTerminalStore,deliveryBy,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0])
                    })
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordPatch(record: PmlParcel): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pml/pml-parcels/${record.id}`;
        const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordDelete(record: PmlParcel): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pml/pml-parcels/${record.id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }

    async getCashRealized(queryString): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pml/pml-parcels/cash-realized${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
        map((res: ApiResponse) => res ));
          return await proRes.toPromise();
      }

      async operatePmlParcel(pmlParcel: PmlParcel, operate): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pml/pml-parcels/operation/${pmlParcel.id}`;
        const proRes = this.apiService.updateApi(url, operate).pipe(
        map((res: ApiResponse) => res ));
          return await proRes.toPromise();
      }
}
