import { Staff } from '../people/staff';
import { Bank } from '../finance';

export class PmtAgent {

    id: string;
    title: string;
    serial: string;
    surname: string;
    otherName: string;
    gender: string;
    address?: string;
    password: string;
    publicKey : string;
    privateKey : string;
    otp: string;
    otpCount: Number;
    otpAccess: Boolean;
    email: string;
    phone?: string;
    businessName?: string;
    businessPhone?: string;
    wallet?: string;
    pin: string;
    remark: string;
    bonus: string;
    bank: Bank;
    bankAccountNumber: string;
    bankAccountName: string;
    nin: string;
    website: string;
    status: string;
    accessLevel: number;
    approvedBy: Staff;
    approvedDate: Date;
    verifiedBy: Staff;
    verifiedDate: Date;
    disengagedBy: Staff;
    disengagedDate: Date;
    
    //* Authentication
    lastLogin: Date;
    currentLogin: Date;
    lastIp: string;
    currentIp: string;
    createdBy?: Staff;
    updatedBy?: Staff;
    deleted?: Boolean;
    deletedAt?: Date;
    deletedBy?: Staff;


    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }

}

export interface PmtAgent {
    [prop: string]: any;
    id: string;
    title: string;
    serial: string;
    surname: string;
    otherName: string;
    gender: string;
    address?: string;
    password: string;
    publicKey : string;
    privateKey : string;
    otp: string;
    otpCount: Number;
    otpAccess: Boolean;
    email: string;
    phone?: string;
    businessName?: string;
    businessPhone?: string;
    wallet?: string;
    pin: string;
    remark: string;
    bonus: string;
    bank: Bank;
    bankAccountNumber: string;
    bankAccountName: string;
    nin: string;
    website: string;
    accessLevel: number;
    status: string;
    approvedBy: Staff;
    approvedDate: Date;
    verifiedBy: Staff;
    verifiedDate: Date;
    disengagedBy: Staff;
    disengagedDate: Date;
    
    //* Authentication
    lastLogin: Date;
    currentLogin: Date;
    lastIp: string;
    currentIp: string;
    createdBy?: Staff;
    updatedBy?: Staff;
    deleted?: Boolean;
    deletedAt?: Date;
    deletedBy?: Staff;


}

