import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PosTransaction, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';

@Injectable()

export class PosTransactions {

    records: PosTransaction[] = [];

    constructor(private apiService: ApiService, private env: EnvService) {
        let queryString = `?sort=-createdAt`;
        queryString += `&populate=terminal,bankAccount`;
        this.recordRetrieve(queryString).then(res => { this.records = res.payload; console.log(res) });
      }

    query(params?: any) {
        if (!params) {
            return this.records;
        }
        return this.records.filter((item) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = item[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return item;
                    } else if (field === params[key]) {
                        return item;
                    }
                }
            }
            return null;
        });
    }

    add(record: PosTransaction) {
        this.records.push(record);
    }

    delete(record: PosTransaction) {
        this.records.splice(this.records.indexOf(record), 1);
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/bank/pos-transactions${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
          map((res: ApiResponse) => res));
        return await proRes.toPromise();
      }


}
    