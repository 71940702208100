import { Staff } from '../people/staff';
import { Terminal } from '../location/terminal';
import { Category } from '../general/category';
import { Product } from '../ecommerce/product';
import { Assignment } from './assignment';
import { Partner } from '../people/partner';

export class Asset {
    id: string;
    code: string;
    assignments: Assignment[]; //! currently
    custodian: 'STAFF'|'PARTNER'; //! currently
    currentStaff?: Staff; //! currently
    currentPartner?: Partner; //! currently
    isAssigned:  boolean;
    category: Category;
    label: string;
    serial: string;
    name: string;
    type: string;
    make: string;
    measure: string;
    description: string;
    terminal: Terminal;
    subsidiary: string;
    isConsumable: boolean;
    usability: Usability;
    worth: Worth;
    launchDate: Date;
    expireDate: Date;
    product: Product;
    openingValue: number;
    closingValue: number;
    salvageValue: number;
    currentValue: number;
    photo: string;
    lifespan: number;
    totalDepreciableCost: number;
    depreciationRate: number;
    depreciationExpense: number;
    accumulatedDepreciation: number;
    depreciableRate: number;
    depreciableExpense: number;
    tarminal: Terminal;
    location: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    // constructor(fields: any) {
    //     // Quick and dirty extend/assign fields to this model
    //     for (const f in fields) {
    //         // @ts-ignore
    //         this[f] = fields[f];
    //     }
    // }
    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }

}

export interface Asset {
    id: string;
    code: string;
    assignments: Assignment[]; //! currently
    custodian: 'STAFF'|'PARTNER'; //! currently
    currentStaff?: Staff; //! currently
    currentPartner?: Partner; //! currently
    isAssigned: boolean;
    category: Category;
    label: string;
    serial: string;
    name: string;
    type: string;
    make: string;
    measure: string;
    description: string;
    terminal: Terminal;
    subsidiary: string;
    isConsumable: boolean;
    usability: Usability;
    worth: Worth;
    launchDate: Date;
    expireDate: Date;
    product: Product;
    openingValue: number;
    closingValue: number;
    salvageValue: number;
    currentValue: number;
    photo: string;
    lifespan: number;
    totalDepreciableCost: number;
    depreciationRate: number;
    depreciationExpense: number;
    accumulatedDepreciation: number;
    depreciableRate: number;
    depreciableExpense: number;
    tarminal: Terminal;
    location: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}


enum Usability {
    DUTY = 'DUTY',
    SCRAP = 'SCRAP',
    SHOP = 'SHOP',
    SOLD = 'SOLD',
    DISPOSED = 'DISPOSED',
}

enum Worth { APPRECIATE = 'APPRECIATE', DEPRECIATE = 'DEPRECIATE' }
