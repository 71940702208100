import { Staff } from '../people/staff';
import { State } from './state';

export class Country {
    id: string;
    iso2: string;
    iso3: string;
    callingCodes: string;
    currencies: string;
    ioc: string;
    languages: string;
    name: string;
    status: string;
    created_by: Staff;
    createdAt?: Date;
    updatedAt?: Date;
    createdBy?: Staff;

    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }

}

export interface Country {
    id: string;
    iso2: string;
    iso3: string;
    callingCodes: string;
    currencies: string;
    ioc: string;
    languages: string;
    name: string;
    status: string;
    created_by: Staff;
    createdAt?: Date;
    updatedAt?: Date;
    createdBy?: Staff;
}
