import { Terminal } from '../location/terminal';
import { Bank } from './bank';
import { Staff } from '../people/staff';
import { Category } from '../general';

export class BankAccount {
    id: string;
    name: string;
    signatory: string;
    subsidiary: string;
    terminal: Terminal;
    bank: Bank;
    accountName: string;
    accountNumber: number;
    accountType: string;
    usage: string;
    type: string;
    category: Category;
    description: string;
    country: string;
    currency: string;
    openingDate: Date;
    closingDate: Date;
    openingBalance: number;
    closingBalance: number;
    lienAmount: number;
    availableBalance: number;
    totalCredit: number;
    totalDebit: number;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }
}
export interface BankAccount {
    id: string;
    name: string;
    signatory: string;
    subsidiary: string;
    terminal: Terminal;
    bank: Bank;
    accountName: string;
    accountNumber: number;
    accountType: string;
    usage: string;
    type: string;
    category: Category;
    description: string;
    country: string;
    currency: string;
    openingDate: Date;
    closingDate: Date;
    openingBalance: number;
    closingBalance: number;
    lienAmount: number;
    availableBalance: number;
    totalCredit: number;
    totalDebit: number;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
