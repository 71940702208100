import {PmlTerminal} from './pml-terminal';

export class PmlArrival {
  pmlTerminal: PmlTerminal;
  arrivedDate: Date;
  arrivedBy: any; // PmlStaff

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}

export interface PmlArrival {
  pmlTerminal: PmlTerminal;
  arrivedDate: Date;
  arrivedBy: any; // PmlStaff
}
