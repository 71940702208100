import { Collection } from "../general/collection";

export class Permission {
    id: string;
    name: string;
    description: string;
    action:'CREATE'|'READ'|'UPDATE'|'DELETE'|'HIDE';
    table: Collection;
    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Permission {
    id: string;
    name: string;
    description: string;
    action:'CREATE'|'READ'|'UPDATE'|'DELETE'|'HIDE';
    table: Collection;
}
