import { Staff } from '../people/staff';
import { Disengagement } from './disengagement';
import { OffenceType } from './offence-type';

export class Offence {
    _id: string;
    id: string;
    code: string;
    offences: Array<OffenceType>;
    offender: Staff;
    offence?: string;
    offenceDate?: Date;
    description?: string;
    disengagement?: Disengagement;
    status?: "PENDING"|"ARBITRATED"|"APPROVED"|"REJECTED";
    verdict?: "INNOCENT"|"GUILTY";
    verdictBy?: Staff;
    verdictDate?: Date;
    verdictRemark?: string;
    fine: number;
    discipline?: "WARNING"|"SUSPENSION"|"DISMISSED";
    suspension?: string;
    payment: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Offence {
    _id: string;
    id: string;
    code: string;
    offences: Array<OffenceType>;
    offender: Staff;
    offenceDate?: Date;
    description?: string;
    disengagement?: Disengagement;
    status?: "PENDING"|"ARBITRATED"|"APPROVED"|"REJECTED";
    verdict?: "INNOCENT"|"GUILTY";
    verdictBy?: Staff;
    verdictDate?: Date;
    verdictRemark?: string;
    fine: number;
    discipline?: "WARNING"|"SUSPENSION"|"DISMISSED";
    suspension?: string;
    payment: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

