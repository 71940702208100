import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, OffenceType } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class OffenceTypes {

  offenceTypes: OffenceType[] = [];

  constructor(private apiService: ApiService,
    private env: EnvService) {
      const queryString = `?sort=-createdAt`;
      this.recordRetrieve(queryString).then(res => { this.offenceTypes = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.offenceTypes;
    }
    return this.offenceTypes.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: OffenceType) {
    this.offenceTypes.push(record);
  }

  delete(record: OffenceType) {
    this.offenceTypes.splice(this.offenceTypes.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/offence-types${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: OffenceType): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/offence-types`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          this.add(res.payload);
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: OffenceType, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/offence-types/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          this.add(res.payload)
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: OffenceType): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/offence-types/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: OffenceType): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/offence-types/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}