import { Staff } from '../people/staff';
import { Customer } from "../crm/customer";
import { EcommerceCategory } from "./ecommerce-category";


export class Image {
    id: string;
    name: string;
    url: string;
    description: string;
    category: EcommerceCategory;
    createdBy: Staff | Customer;
    createdAt?: Date;
    updatedBy: Staff | Customer;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Image {
    id: string;
    name: string;
    url: string;
    description: string;
    category: EcommerceCategory;
    createdBy: Staff | Customer;
    createdAt?: Date;
    updatedBy: Staff | Customer;
    updatedAt?: Date;
}
