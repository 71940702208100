import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { PmlSchedule, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class PmlSchedules {

  pmlSchedules: PmlSchedule[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=pmlShipment,pmlParcel,pmlPackage,pmlTerminalTo,pmlTerminalFrom`;
    queryString += `,pmlPartner,pmlDriver,pmlVehicle,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.pmlSchedules = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.pmlSchedules;
    }
    return this.pmlSchedules.filter((item) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = item[key];
          if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });
  }

  add(record: PmlSchedule) {
    this.pmlSchedules.push(record);
  }

  delete(record: PmlSchedule) {
    this.pmlSchedules.splice(this.pmlSchedules.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-schedules${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: PmlSchedule): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-schedules`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmlShipment,pmlWaybill,pmlTerminal`;
          queryString += `,pmlPartner,pmlDriver,pmlVehicle,pmlRoutes,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0]);
          });
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: PmlSchedule, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-schedules/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmlShipment,pmlWaybill,terminal,terminalTo,terminalFrom`;
          queryString += `,partner,driver,vehicle,staffForwarding,staffReceiving,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: PmlSchedule): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-schedules/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: PmlSchedule): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-schedules/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordIncoming(record: PmlSchedule, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-schedules/receiving/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmlShipment,pmlWaybill,terminal,terminalTo,terminalFrom`;
          queryString += `,partner,driver,vehicle,staffForwarding,staffReceiving,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }
}
