import { Vehicle } from '../factory/vehicle';
import { Office } from './office';
import { Terminal } from '../location/terminal';
import { Rating } from '../crm/rating';
import { Role } from './role';
import { County } from '../location/county';
import { State } from '../location/state';
import { Bank } from '../finance/bank';
import { Assignment } from '../finance';
import { Category } from '../general';

export class PmlStaff {
    id: string;
    serial?: string;
    category?: Category;
    title?: string;
    surname: string;
    otherName: string;
    fullName: string;
    gender: string;
    birthDate: Date;
    maritalStatus: string;
    children?: number;
    phone?: string;
    phoneHome?: string;
    address?: string;
    village?: string;
    state: State;
    county: County;
    country?: string;
    email?: string;
    password?: string;
    otp?: string;
    otpCount?: number;
    kin: string;
    kinPhone: string;
    kinAddress: string;
    guarantor1: string;
    guarantor1Phone: string;
    guarantor1Address: string;
    guarantor2?: string;
    guarantor2Phone?: string;
    guarantor2Address?: string;
    profession?: string;
    qualification?: string;
    institution?: string;
    tin?: number;
    annualIncome?: number;
    basicSalary?: number;
    bonus?: number;
    entertainmentAllowance?: number;
    houseAllowance?: number;
    lunchAllowance?: number;
    medicalAllowance?: number;
    transportAllowance?: number;
    utilityAllowance?: number;
    welfareAllowance?: number;
    pension?: number;
    assurance?: number;
    bank?: Bank;
    bankAccountNumber?: string;
    bankAccountName?: string;
    rank?: string;
    office: Office;
    role: Role;
    superior: PmlStaff;
    subsidiary?: string;
    terminal: Terminal;
    assignments: Assignment[];
    isAssignedVehicle?: boolean;
    currentVehicle?: Vehicle;
    notice?: string;
    ratings?: Array<Rating>;
    remark?: string;
    photo?: string;
    isSalaryPayable?: boolean;
    isDocumentComplete?: boolean;
    accessLevel?: string;

    employment: 'EMPLOYED'|'FULLTIME'|'PARTTIME'|'LEAVE'|'PROBATED'|'SUSPENDED'|'RETIRED'| 'DISENGAGED'|'UNKNOWN';
    employedDate: Date;
    employedBy: PmlStaff;
    parttimedDate: Date;
    parttimedBy: PmlStaff;
    fulltimedDate: Date;
    fulltimedBy: PmlStaff;
    leaveDate: Date;
    leaveBy: PmlStaff;
    probatedDate: Date;
    probatedBy: PmlStaff;
    suspendedDate: Date;
    suspendedBy: PmlStaff;
    retiredDate: Date;
    retiredBy: PmlStaff;
    disengagedDate: Date;
    disengagedBy: PmlStaff;
    employmentRemark: PmlStaff;

    //* Approval
    status: 'PENDING'|'APPROVED'|'REJECTED';
    approvedDate: Date;
    approvedBy: PmlStaff;
    rejectedDate: Date;
    rejectedBy: PmlStaff;
    approvalRemark: String;
    //* Authentication
    lastLogin: Date;
    currentLogin: Date;
    lastIp: String;
    currentIp: String;

    createdBy?: PmlStaff;
    createdAt?: Date;
    updatedBy?: PmlStaff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface PmlStaff {
    id: string;
    serial?: string;
    category?: Category;
    title?: string;
    surname: string;
    otherName: string;
    fullName: string;
    gender: string;
    birthDate: Date;
    maritalStatus: string;
    children?: number;
    phone?: string;
    phoneHome?: string;
    address?: string;
    village?: string;
    state: State;
    county: County;
    country?: string;
    email?: string;
    password?: string;
    otp?: string;
    otpCount?: number;
    kin: string;
    kinPhone: string;
    kinAddress: string;
    guarantor1: string;
    guarantor1Phone: string;
    guarantor1Address: string;
    guarantor2?: string;
    guarantor2Phone?: string;
    guarantor2Address?: string;
    profession?: string;
    qualification?: string;
    institution?: string;
    tin?: number;
    annualIncome?: number;
    basicSalary?: number;
    bonus?: number;
    entertainmentAllowance?: number;
    houseAllowance?: number;
    lunchAllowance?: number;
    medicalAllowance?: number;
    transportAllowance?: number;
    utilityAllowance?: number;
    welfareAllowance?: number;
    pension?: number;
    assurance?: number;
    bank?: Bank;
    bankAccountNumber?: string;
    bankAccountName?: string;
    rank?: string;
    office: Office;
    role: Role;
    superior: PmlStaff;
    subsidiary?: string;
    terminal: Terminal;
    assignments: Assignment[];
    isAssignedVehicle?: boolean;
    currentVehicle?: Vehicle;
    notice?: string;
    ratings?: Array<Rating>;
    remark?: string;
    photo?: string;
    isSalaryPayable?: boolean;
    isDocumentComplete?: boolean;
    accessLevel?: string;

    employment: 'EMPLOYED'|'FULLTIME'|'PARTTIME'|'LEAVE'|'PROBATED'|'SUSPENDED'|'RETIRED'| 'DISENGAGED'|'UNKNOWN';
    employedDate: Date;
    employedBy: PmlStaff;

    parttimedDate: Date;
    parttimedBy: PmlStaff;
    fulltimedDate: Date;
    fulltimedBy: PmlStaff;
    leaveDate: Date;
    leaveBy: PmlStaff;
    probatedDate: Date;
    probatedBy: PmlStaff;
    suspendedDate: Date;
    suspendedBy: PmlStaff;
    retiredDate: Date;
    retiredBy: PmlStaff;
    disengagedDate: Date;
    disengagedBy: PmlStaff;
    employmentRemark: PmlStaff;

    //* Approval
    status: 'PENDING'|'APPROVED'|'REJECTED';
    approvedDate: Date;
    approvedBy: PmlStaff;
    rejectedDate: Date;
    rejectedBy: PmlStaff;
    approvalRemark: String;
    //* Authentication
    lastLogin: Date;
    currentLogin: Date;
    lastIp: String;
    currentIp: String;

    createdBy?: PmlStaff;
    createdAt?: Date;
    updatedBy?: PmlStaff;
    updatedAt?: Date;

}
