import { Staff } from '../people/staff';
import { Transactions } from "../../providers";


export class EwalletBankRegister {
    id: string;
    code: string;
    amount: Number;
    depositedBy: string;
    depositedDate: Date;
    teller: string
    recordStatus: "PENDING" | "REJECTED" | "ACKNOWLEDGED" |  "APPROVED" | "AUTHORIZED" | "AUDITED" | "CLOSED";
    transactions: Transactions[];
    auditedStatus: "PENDING" | "REJECTED" | "ACKNOWLEDGED" |  "APPROVED" | "AUTHORIZED" | "AUDITED" | "CLOSED";
    auditedBy: Staff;
    auditedDate: Date;
    auditedRemark: String;
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface EwalletBankRegister {
    id: string;
    code: string;
    amount: Number;
    depositedBy: string;
    depositedDate: Date;
    teller: string
    recordStatus: "PENDING" | "REJECTED" | "ACKNOWLEDGED" |  "APPROVED" | "AUTHORIZED" | "AUDITED" | "CLOSED";
    transactions: Transactions[];
    auditedStatus: "PENDING" | "REJECTED" | "ACKNOWLEDGED" |  "APPROVED" | "AUTHORIZED" | "AUDITED" | "CLOSED";
    auditedBy: Staff;
    auditedDate: Date;
    auditedRemark: String;
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;
}
