import { PmtTerminal } from './pmt-terminal';
import { PmtVehicle } from './pmt-vehicle';
import { Staff } from '../people/staff';
import { Customer } from '../crm/customer';
import { County } from '../location';
import { Partner } from '../people';
import { Category } from '../general';



export class PmtHiring {
    id: string;
    code: string;
    type: 'TERMINAL_TERMINAL'|'HOME_HOME'|'TERMINAL_HOME'|'HOME_TERMINAL';
    tripType: 'ONE_WAY'|'TWO_WAY';
    category: Category;
    cashier: Staff;
    partner: Partner;
    departureDate: Date;
    returnDate: Date;
    estimatedCharge: number;
    actualCharge: number;
    pmtVehicle: PmtVehicle;
    vehicleType: string;
    customer: Customer;
    pmtTerminalFrom: PmtTerminal;
    pmtTerminalTo: PmtTerminal;
    paymentStatus: string;
    paymentMethod: string;
    paymentGateway: string;
    paymentDate: Date;
    paymentBy: Staff;
    countyFrom: County;
    countyTo: County;
    longitudeFrom: number;
    longitudeTo: number;
    latitudeFrom: number;
    latitudeTo: number;
    tripDuration: number; // days
    status: string;
    description: string;
    createdBy?: Staff | Customer;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface PmtHiring {
    id: string;
    code: string;
    type: 'TERMINAL_TERMINAL'|'HOME_HOME'|'TERMINAL_HOME'|'HOME_TERMINAL';
    tripType: 'ONE_WAY'|'TWO_WAY';
    category: Category;
    cashier: Staff;
    partner: Partner;
    departureDate: Date;
    returnDate: Date;
    estimatedCharge: number;
    actualCharge: number;
    pmtVehicle: PmtVehicle;
    vehicleType: string;
    customer: Customer;
    pmtTerminalFrom: PmtTerminal;
    pmtTerminalTo: PmtTerminal;
    paymentStatus: string;
    paymentMethod: string;
    paymentGateway: string;
    paymentDate: Date;
    paymentBy: Staff;
    countyFrom: County;
    countyTo: County;
    longitudeFrom: number;
    longitudeTo: number;
    latitudeFrom: number;
    latitudeTo: number;
    tripDuration: number; // days
    status: string;
    description: string;
    createdBy?: Staff | Customer;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
