import { Staff } from '../people/staff';
import { Customer } from '../crm/customer';
import { Sale } from './sale';
import { Image } from './image';


export class EcommerceCategory {
    id: string;
    code: string;
    name: string;
    cost: number;
    density: number;
    type: 'PHYSICAL' | 'DIGITAL' | 'SERVICE';
    description: string;
    parent: EcommerceCategory;
    image: Image;
    level: number;
    children: EcommerceCategory[];
    isOdiferous: boolean;
    isCombustible: boolean;
    isUnique: boolean;
    isLiquid: boolean;
    isPerishable: boolean;
    isFragile: boolean;
    createdBy: Staff | Customer;
    createdAt?: Date;
    updatedBy: Staff | Customer;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface EcommerceCategory {
    id: string;
    code: string;
    name: string;
    cost: number;
    type: 'PHYSICAL' | 'DIGITAL' | 'SERVICE';
    description: string;
    parent: EcommerceCategory;
    image: Image;
    level: number;
    children: EcommerceCategory[];
    isOdiferous: boolean;
    isCombustible: boolean;
    isUnique: boolean;
    isLiquid: boolean;
    isPerishable: boolean;
    isFragile: boolean;
    createdBy: Staff | Customer;
    createdAt?: Date;
    updatedBy: Staff | Customer;
    updatedAt?: Date;
}
