import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Author, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Authors {

  authors: Author[] = [];
  private queryString: string;

  constructor(private apiService: ApiService, private env: EnvService) {
    this.queryString = `?sort=-createdAt,-updatedAt&limit=1000`;
    this.queryString += `&populate=cart,terminal,ratings,saleOrders,pmtBoardings,pmlShipments,createdBy,updatedBy`;
    this.recordRetrieve(this.queryString).then(res => { this.authors = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.authors;
    }
    return this.authors.filter((author) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = author[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return author;
            } else if (field === params[key]) {
              return author;
            }
          }
      }
      return null;
    });
  }

  add(record: Author) {
    this.authors.push(record);
  }

  delete(record: Author) {
    this.authors.splice(this.authors.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/blog/authors${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Author): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/blog/authors`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          this.queryString += `&populate=cart,terminal,ratings,saleOrders,pmtBoardings,pmlShipments,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
}

async recordUpdate(record: Author, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/blog/authors/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          this.queryString += `&populate=cart,terminal,ratings,saleOrders,pmtBoardings,pmlShipments,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
}

async recordPatch(record: Author): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/blog/authors/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Author): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/blog/authors/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }


  async getAuthor(prop: string, val: any): Promise<Author> {
    console.log('\nCalling getAuthor');
    const authorObj = this.authors.find(item => item[ prop ] === val);
    if (authorObj) return authorObj;
    const query = prop === 'id' ? `?_id=${val}` : `?${prop}=${val}`;
    await this.recordRetrieve(query)
      .then((res: ApiResponse) => {
        console.log('\ngetAuthor ==>', res);
        if (res.success) return res.payload[0] || [];
      }).catch(e => e.message);
  }
}
