import { Staff } from '../people/staff';
import { Sale } from '../ecommerce/sale';
import { Cart } from '../ecommerce/cart';
import { Rating } from '../crm/rating';

export class EcommerceCustomer {
    id: string;
    title: string;
    surname: string;
    otherName: string;
    gender: string;
    phone: string;
    email: string;
    password: string;
    otp: string;
    otpCount: number;
    otpAccess: Boolean;
    referral: string;
    address: string;
    country: string;
    wallet: number;
    cart: Cart;
    ratings: Rating[];
    sales: Sale[];
    isPhoneVerified: boolean;
    isEmailVerified: boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
    points?: number;
    remark?: string;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface EcommerceCustomer {
    id: string;
    title: string;
    surname: string;
    otherName: string;
    gender: string;
    phone: string;
    email: string;
    password: string;
    otp: string;
    otpCount: number;
    otpAccess: Boolean;
    referral: string;
    address: string;
    country: string;
    wallet: number;
    cart: Cart;
    ratings: Rating[];
    sales: Sale[];
    isPhoneVerified: boolean;
    isEmailVerified: boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
    points?: number;
    remark?: string;
}
