import { Terminal } from '../location';
import { Staff } from '../people/staff';
import { Fault } from './fault';

export class Garage {
    id: string;
    name: string;
    rating: number; // 1 - 5
    description: string;
    phone: string;
    email: string;
    address: string;
    staffList: Staff; // [{ type: ObjectId }],
    faults: Fault; // [{ type: ObjectId, ref: "Fault" }],
    manager: Staff;
    terminal: Terminal;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Garage {
    id: string;
    name: string;
    rating: number; // 1 - 5
    description: string;
    phone: string;
    email: string;
    address: string;
    staffList: Staff; // [{ type: ObjectId }],
    faults: Fault; // [{ type: ObjectId, ref: "Fault" }],
    manager: Staff;
    terminal: Terminal;

}
