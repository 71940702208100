import {PmlVehicle} from '../pml/pml-vehicle';
import {County, State} from '../location';
import {Rating} from '../crm';
import {Bank} from '../finance';
import {Staff} from '../people';
import {Sale} from '../ecommerce';
import {Maintenance} from '../workshop';
import {PmlPackage} from '../pml/pml-package';

export class PmlDriver {
  id: string;
  title: string;
  serial: string;
  type: string;
  pmlCategory: string; //{ type: ObjectId, ref: "PmlCategory" },
  vehiclesOwned: PmlVehicle;
  pmlAssignments: string ;//[{ type: ObjectId, ref: "PmlAssignment" }],
  isAssignedPmlVehicle: boolean;
  currentPmlVehicle: PmlVehicle;
  driverLicence: string;
  surname: string;
  otherName: string;
  gender: string;
  birthDate: Date;
  maritalStatus: string;
  address: string;
  state: State;
  county: County;
  country: string;
  region: string; //{ type: ObjectId, ref: "PmlRegion" },
  password: string;
  otp: string; // { type: String },
  otpCount: number;
  otpAccess: boolean;
  email: string;
  phone: string;
  phoneHome: string;
  kin: string;
  kinPhone: string;
  kinAddress: string;
  guarantor1: string;
  guarantor1Address: string;
  guarantor1Phone: string;
  guarantor2: string;
  guarantor2Address: string;
  guarantor2Phone: string;
  photo: string;
  qualification: string;
  institution: string;
  notice: string;
  ratings: Rating;
  remark: string;
  bonus: string;
  bank: Bank;
  bankAccountNumber: string;
  bankAccountName: string;
  documents: any;
  isDocumentComplete: boolean;
  status: string;
  approvedBy: Staff;
  approvedDate: Date;
  verifiedBy: Staff;
  verifiedDate: Date;
  disengagedBy: Staff;
  disengagedDate: Date;
  wallet: number;
  sales: Sale;
  maintenances: Maintenance;
  pmlPackages: PmlPackage[];
  createdBy: Staff;
  updatedBy: Staff;
  deleted: boolean;
  deletedAt: Date;
  deletedBy: Staff;
  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}
export interface PmlDriver {
  id: string;
  title: string;
  serial: string;
  type: string;
  pmlCategory: string; //{ type: ObjectId, ref: "PmlCategory" },
  vehiclesOwned: PmlVehicle;
  pmlAssignments: string ;//[{ type: ObjectId, ref: "PmlAssignment" }],
  isAssignedPmlVehicle: boolean;
  currentPmlVehicle: PmlVehicle;
  driverLicence: string;
  surname: string;
  otherName: string;
  gender: string;
  birthDate: Date;
  maritalStatus: string;
  address: string;
  state: State;
  county: County;
  country: string;
  region: string; //{ type: ObjectId, ref: "PmlRegion" },
  password: string;
  otp: string; // { type: String },
  otpCount: number;
  otpAccess: boolean;
  email: string;
  phone: string;
  phoneHome: string;
  kin: string;
  kinPhone: string;
  kinAddress: string;
  guarantor1: string;
  guarantor1Address: string;
  guarantor1Phone: string;
  guarantor2: string;
  guarantor2Address: string;
  guarantor2Phone: string;
  photo: string;
  qualification: string;
  institution: string;
  notice: string;
  ratings: Rating;
  remark: string;
  bonus: string;
  bank: Bank;
  bankAccountNumber: string;
  bankAccountName: string;
  documents: any;
  isDocumentComplete: boolean;
  status: string;
  approvedBy: Staff;
  approvedDate: Date;
  verifiedBy: Staff;
  verifiedDate: Date;
  disengagedBy: Staff;
  disengagedDate: Date;
  wallet: number;
  sales: Sale;
  maintenances: Maintenance;
  pmlPackages: PmlPackage[];
  createdBy: Staff;
  updatedBy: Staff;
  deleted: boolean;
  deletedAt: Date;
  deletedBy: Staff;
}