import { Staff } from '../people/staff';
import { Account } from './account';
import { Charge } from './charge';
import { Credit } from './credit';
import { Cashout } from './cashout';


export class Transaction {
    id: string;
    code: string;
    type: "SPEND" | "CREDIT" | "CASHOUT" | "TRANSFER";
    credit: Credit;
    cashout: Cashout;
    amount: string;
    charges: Charge[];
    userFrom: Account;
    userTo: Account;
    walletFrom: string;
    walletTo: string;
    description: string;
    narration: string;
    subsidiary: "PML" | "PMT" | "ECOMMERCE";
    status: "SUCCESSFUL" | "PENDING" | "FAIL";
    auditedBy: Staff;
    auditedDate: Date;
    auditedRemark: string;
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Transaction {
    id: string;
    code: string;
    type: "SPEND" | "CREDIT" | "CASHOUT" | "TRANSFER";
    credit: Credit;
    cashout: Cashout;
    amount: string;
    charges: Charge[];
    userFrom: Account;
    userTo: Account;
    walletFrom: string;
    walletTo: string;
    description: string;
    narration: string;
    subsidiary: "PML" | "PMT" | "ECOMMERCE";
    status: "SUCCESSFUL" | "PENDING" | "FAIL";
    auditedBy: Staff;
    auditedDate: Date;
    auditedRemark: string;
    createdBy: Staff;
    createdAt?: Date;
    updatedBy: Staff;
    updatedAt?: Date;
}
