import { County } from '../location';
import { Staff } from '../people/staff';
import { State } from '../location/state';
import { Category } from '../general/category';
import { PmlZone } from '../pml';
import { PmlRegion } from '../pml';
import { Rating } from '../crm';

export class PmlTerminal {

    id: string;
    code: string;
    type: string;
    category: Category;
    name: string;
    manager?: Staff;
    phone?: string;
    quarter?: string;
    county?: County;
    state?: State;
    address?: string;
    longitude?: number;
    latitude?: number;
    capacity?: number;
    photo?: string;
    subsidiary: string;
    supervisor: Staff;
    email: string;
    pmlZone: PmlZone;
    pmlRegion: PmlRegion;
    isActive: boolean;
    ratings: Rating[];
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;


    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }

}

export interface PmlTerminal {
    [prop: string]: any;
    id: string;
    code: string;
    type: string;
    category: Category;
    name: string;
    manager?: Staff;
    phone?: string;
    quarter?: string;
    county?: County;
    state?: State;
    address?: string;
    longitude?: number;
    latitude?: number;
    capacity?: number;
    photo?: string;
    subsidiary: string;
    supervisor: Staff;
    email: string;
    pmlZone: PmlZone;
    pmlRegion: PmlRegion;
    isActive: boolean;
    ratings: Rating[];
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

