import { Terminal } from "../location";
import { Staff } from "../people";


export class Parking  {
    id: string;
    code: string;
    terminal: Terminal;
    driverName: string;
    driverPhone: string;
    plateNumber: string;
    vehicleMake: string;
    vehicleType: string;
    space: string;
    initialCharge: Number;
    hourlyRate: Number;
    checkIn: Date;
    checkOut: Date;
    remark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}
