import { Staff } from '../people/staff';
import { BlogComment } from './blog-comment';
import { BlogCategory } from './blog-category';
import { Author } from './author';

export class Blog {
    id: string;
    title: string;
    subtitle: string;
    image: string;
    body: string;
    tags: string;
    author: Author; 
    slug: string;
    isPublished: string;
    comments: BlogComment[];
    category: BlogCategory;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Blog {
    id: string;
    title: string;
    subtitle: string;
    image: string;
    body: string;
    tags: string;
    author: Author; 
    slug: string;
    isPublished: string;
    comments: BlogComment[];
    category: BlogCategory;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

}
