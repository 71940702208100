import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private toastr: ToastrService) {
    }

    success(message) {
        this.toastr.show(`<span class="fa fa-check"></span> ${message}`, ``, {
            timeOut: 8000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-success alert-with-icon',
        });
    }

    error(message) {
        this.toastr.show(`<span class="fa fa-times"></span> ${message} `, '', {
            timeOut: 8000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-danger alert-with-icon',
        });
    }

    warn(message) {
        this.toastr.show(`<span class="fa fa-warn"></span> ${message} `, '', {
            timeOut: 8000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-warning alert-with-icon',
        });
    }

    info(message) {
        this.toastr.show(`<span class="fa fa-info"></span> ${message} `, '', {
            timeOut: 8000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-info alert-with-icon',
        });
    }

}
