import { PmtRoute } from './pmt-route';
import { Customer } from '../crm/customer';
import { PmtSchedule } from './pmt-schedule';
import { PmtReservation } from './pmt-reservation';
import { Staff } from '../people/staff';
import { PmtTerminal } from './pmt-terminal';

export class PmtBoarding {
  id: string;
  pmtTerminal: PmtTerminal; //* Reduncdant
  pmtSchedule: PmtSchedule; // Schedule Object
  pmtReservation: PmtReservation; // Reservation Object
  pmtRoute: PmtRoute;
  customer: Customer; // Customer Object
  code?: string;
  paymentStatus: string;
  paymentMethod: string;
  paymentGateway?: string;
  seatQuantity: number;
  seatPositions?: [];
  fare: number;
  amount: number;
  transactionDate: Date;
  destination?: string;
  boardingStatus: string;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
        // @ts-ignore
        this[f] = fields[f];
    }
}

}

export interface PmtBoarding {
  [prop: string]: any;
  id: string;
  pmtTerminal: PmtTerminal; //* Reduncdant
  pmtSchedule: PmtSchedule; // Schedule Object
  pmtReservation: PmtReservation; // Reservation Object
  pmtRoute: PmtRoute;
  customer: Customer; // Customer Object
  code?: string;
  paymentStatus: string;
  paymentMethod: string;
  paymentGateway?: string;
  seatQuantity: number;
  seatPositions?: [];
  fare: number;
  amount: number;
  destination?: string;
  transactionDate: Date;
  boardingStatus: string;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;
}

