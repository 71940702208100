import { Staff } from './staff';
import { Category } from '../general/category';
import { Office } from './office';

export class Appointment {
    id: string;
    code: string;
    category: Category;
    appointee: Staff;
    office: Office;
    status: 'PENDING'|'APPROVED'|'REJECTED';
    expectedDate: Date;
    appointedDate: Date;
    appointedBy: Staff;
    approvedDate: Date;
    approvedBy: Staff;
    rejectedDate: Date;
    rejectedBy: Staff;
    remark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Appointment {
    id: string;
    code: string;
    category: Category;
    appointee: Staff;
    office: Office;
    status: 'PENDING'|'APPROVED'|'REJECTED';
    expectedDate: Date;
    appointedDate: Date;
    appointedBy: Staff;
    approvedDate: Date;
    approvedBy: Staff;
    rejectedDate: Date;
    rejectedBy: Staff;
    remark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
