import { Vehicle } from '../factory/vehicle';
import { Partner } from '../people/partner';
import { Fault } from './fault';
import { Stage } from '../general/stage';
import { Staff } from '../people/staff';
import { Product } from '../ecommerce';
import { Garage } from './garage';

export class Maintenance {
    id: string;
    registerDate: Date;
    vehicle: Vehicle;
    partner: Partner;
    complaint: string; // comment: "drivers report "
    garage: Garage;
    diagnosis: string; // comment: "troubleshoot report by Mechanic"
    faults: Array<Fault>;
    spares: Array<{ product: Product, quantity: number, unitPrice: Number }>; // object in object
    stage: Stage;  // default: "PENDING"
    partnerRemark: string;
    certifiedBy: string;
    certifiedDate:	Date;
    certifiedRemark:	string;
  //  items: Array<{ product: Product, quantity: number, unitPrice: Number }>;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
    

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface PmtMaintenance {
    id: string;
    registerDate: Date;
    vehicle: Vehicle;
    partner: Partner;
    complaint: string; // comment: "drivers report "
  //  garage: Team;
    diagnosis: string; // comment: "troubleshoot report by Mechanic"
    faults: Array<Fault>;
    spares: Product; // object in object
    stage: Stage;  // default: "PENDING"
    partnerRemark: string;
    certifiedBy: string;
    certifiedDate:	Date;
    certifiedRemark:	string;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
