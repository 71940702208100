import { Category } from '../general/category';
import { Staff } from '../people/staff';

export class Material {
    id: string;
    name: string;
    type?: string;
    code:string;
    category: Category;
    description: string;
    subsidiary: string;
    measure: number;
    volume: number;
    mass: number;
    unit: string;
    cost: number;
    photo: string;
    variants: string;
    surfaceArea: string;
    dimension: string;
    attributes: object;
    quantityStock: number;
    quantityOrder: number;
    reorderLevel: number;
    reorderQuantity: number;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Material {
    id: string;
    name: string;
    type?: string;
    code:string;
    category: Category;
    subsidiary: string;
    measure: number;
    volume: number;
    mass: number;
    unit: string;
    cost: number;
    photo: string;
    variants: string;
    surfaceArea: string;
    description: string;
    dimension: string;
    attributes: object;
    quantityStock: number;
    quantityOrder: number;
    reorderLevel: number;
    reorderQuantity: number;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
