import { Staff } from '../people/staff';

export class AccidentCause
{
    id: string;
    name:	string;	
    offender:	string;	
    description: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
    
    }
    
    export interface AccidentCause {
        
        id: string;
        name:	string;	
        offender:	string;	
        description: string;
        createdBy?: Staff;
        createdAt?: Date;
        updatedBy?: Staff;
        updatedAt?: Date;
    
    }
    