import {BankAccount} from "./bank-account";
import { Terminal } from "../location";

export class PosTransaction {

  id: string;
  transactionRef: string;
  transactionDate: Date;
  accountNo: string;
  posTerminalId: string;
  posTerminalName: string;
  narration: string;
  amount: string;
  tranType: string;
  businessName: string;
  businessId: string;
  currency: string;
  bankName: string;
  bankCode: string;
  //* process data
  code: string;
  channel: String;
  bankAccount: BankAccount;
  subsidiary: string;
  terminal: Terminal;
	createdAt?: Date;
  updatedAt?: Date;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }

}

export interface PosTransaction {

  id: string;
  transactionRef: string;
  transactionDate: Date;
  accountNo: string;
  posTerminalId: string;
  posTerminalName: string;
  narration: string;
  amount: string;
  tranType: string;
  businessName: string;
  businessId: string;
  currency: string;
  bankName: string;
  bankCode: string;
  //* process data
  code: string;
  channel: String;
  bankAccount: BankAccount;
  subsidiary: string;
  terminal: Terminal;
	createdAt?: Date;
  updatedAt?: Date;

}
