import { Staff } from '../people/staff';

export class OffenceType {
    id: string;
    offenderType: string;
    name: string;
    code: string;
    description: string;
    fine: number;
    discipline : string;
    suspensionDays: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface OffenceType {
    // [prop: string]: any;
    id: string;
    offenderType: string;
    name: string;
    code: string;
    description: string;
    fine: number;
    discipline : string;
    suspensionDays: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

}

