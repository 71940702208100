import { Staff } from '../people/staff';
import {PmlParcel} from "./pml-parcel";
import {Partner} from "../people/partner";
import {PmlVehicle} from "./pml-vehicle";
import {PmlTerminal} from "./pml-terminal";

export class PmlImpound {
    id: string;
    code: string;
    pmlParcel: PmlParcel;
    senderName: string;
    recipientName: string;
    senderPhone: string;
    recipientPhone: string;
    description: string;
    pmlDriver : Partner;
    pmtPartner: Partner;
    vehicle: PmlVehicle;
    pmlTerminalFrom: PmlTerminal;
    pmlTerminalTo: PmlTerminal;
    pmlTerminalStore: PmlTerminal;
    invoice: any;
    fine: number;
    parcelCharge: number;
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    status: string;
    paymentDate: Date;
    impoundedDate: Date;
    impoundedBy: Staff;
    remark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface PmlImpound {
// [prop: string]: any;
    id: string;
    code: string;
    pmlParcel: PmlParcel;
    senderName: string;
    recipientName: string;
    senderPhone: string;
    recipientPhone: string;
    description: string;
    pmlDriver : Partner;
    pmtPartner: Partner;
    vehicle: PmlVehicle;
    pmlTerminalFrom: PmlTerminal;
    pmlTerminalTo: PmlTerminal;
    pmlTerminalStore: PmlTerminal;
    invoice: any;
    fine: number;
    shipmentCharge: number;
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    status: string;
    paymentDate: Date;
    impoundedDate: Date;
    impoundedBy: Staff;
    remark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

