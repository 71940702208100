export * from './appointment';
export * from './attendance';
export * from './offence';
export * from './offence-type';
export * from './office';
export * from './partner';
export * from './permission';
export * from './role';
export * from './staff';
export * from './driver';
export * from './pml-staff';
export * from './salary-grade';
export * from './entrepreneur';
export * from './entreprise';
export * from './transfer';
export * from './disengagement';
