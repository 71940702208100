import { Region, Terminal, Zone } from "../location";
import { Office, Partner, Staff } from "../people";

export class Memo {
    id: string;
    code: string;
    sender: Staff;
    scope: string;
    partnerRecipient: Partner;
    staffRecipient: Staff;
    office: Office;
    terminal: Terminal;
    zone: Zone;
    region: Region;
    subsidiary: string;
    subject: string;
    message: string;
    status: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;


    constructor(fields: any) {
        for (const f in fields) {
            this[f] = fields[f];
            }
        }
}

export interface Memo {
    id: string;
    code: string;
    sender: Staff;
    scope: string;
    partnerRecipient: Partner;
    staffRecipient: Staff;
    office: Office;
    terminal: Terminal;
    zone: Zone;
    region: Region;
    subsidiary: string;
    subject: string;
    message: string;
    status: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}